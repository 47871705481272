export const LANGUAGE_CODES = {
  NA: 'N/A',
  '001': '001 Abkhazian',
  '002': '002 Afan (Oromo)',
  '003': '003 Afar',
  '004': '004 Afrikaans',
  '005': '005 Albanian',
  '006': '006 Amharic',
  '007': '007 Arabic',
  '008': '008 Armenian',
  '009': '009 Assamese',
  '010': '010 Zerbaijani',
  '011': '011 Bashkir',
  '012': '012 Basque',
  '013': '013 Bengali;Bangla',
  '014': '014 Bhutani',
  '015': '015 Bihari',
  '016': '016 Bislama',
  '017': '017 Breton',
  '018': '018 Bulgarian',
  '019': '019 Burmese',
  '020': '020 Byelorussian',
  '021': '021 Cambodian',
  '022': '022 Catalan',
  '023': '023 Chinese',
  '024': '024 Corsican',
  '025': '025 Croatian',
  '026': '026 Czech',
  '027': '027 Danish',
  '028': '028 Dutch',
  '140': '140 English',
  '030': '030 Esperonto',
  '031': '031 Estonian',
  '032': '032 Faroese',
  '033': '033 Fiji',
  '034': '034 Finnish',
  '035': '035 French',
  '036': '036 Frisian',
  '037': '037 Galican',
  '038': '038 Georgian',
  '039': '039 German',
  '040': '040 Greek',
  '041': '041 Greenlandic',
  '042': '042 Guarani',
  '043': '043 Gujarati',
  '044': '044 Hausa',
  '045': '045 Hebrew',
  '046': '046 Hindi',
  '047': '047 Hungarian',
  '048': '048 Icelandic',
  '049': '049 Indonesian',
  '050': '050 Interlingua',
  '051': '051 Interlingue',
  '052': '052 Inuktitut',
  '053': '053 Inupiak',
  '054': '054 Irish',
  '055': '055 Italian',
  '056': '056 Japanese',
  '057': '057 Javanese',
  '058': '058 Kannada',
  '059': '059 Kashmiri',
  '060': '060 Kazakh',
  '061': '061 Kinyarwanda',
  '062': '062 Kirghiz',
  '063': '063 Kurundi',
  '064': '064 Korean',
  '065': '065 Kurdish',
  '066': '066 Laothian',
  '067': '067 Latin',
  '068': '068 Latvian;Lettish',
  '069': '069 Lingala',
  '070': '070 Lithuanian',
  '071': '071 Macedonian',
  '072': '072 Malagasy',
  '073': '073 Malay',
  '074': '074 Malayalam',
  '075': '075 Maltese',
  '076': '076 Maori',
  '077': '077 Marathi',
  '078': '078 Moldavian',
  '079': '079 Mongolian',
  '080': '080 Nauru',
  '081': '081 Nepali',
  '082': '082 Norwegian',
  '083': '083 Occitan',
  '084': '084 Oriya',
  '085': '085 Pashto;Pushto',
  '086': '086 Persian (Farsi)',
  '087': '087 Polish',
  '088': '088 Portuguese',
  '089': '089 Punjabi',
  '090': '090 Quechua',
  '091': '091 Rhaeto-Romance',
  '092': '092 Romanian',
  '093': '093 Russian',
  '094': '094 Samoan',
  '095': '095 Sangho',
  '096': '096 Sanskrit',
  '097': '097 Scot Gaelic',
  '098': '098 Serbian',
  '099': '099 Serbo-Croatian',
  '100': '100 Sesotho',
  '101': '101 Setswana',
  '102': '102 Shona',
  '103': '103 Sindhi',
  '104': '104 Singhalese',
  '105': '105 Siswati',
  '106': '106 Slovak',
  '107': '107 Slovenian',
  '108': '108 Somali',
  '109': '109 Spanish',
  '110': '110 Sundanese',
  '111': '111 Swahili',
  '112': '112 Swedish',
  '113': '113 Tagalog',
  '114': '114 Tajik',
  '115': '115 Tamil',
  '116': '116 Tatar',
  '117': '117 Telugu',
  '118': '118 Thai',
  '119': '119 Tibetan',
  '120': '120 Tigrinya',
  '121': '121 Tonga',
  '122': '122 Tsonga',
  '123': '123 Turkish',
  '124': '124 Turkmen',
  '125': '125 Twi',
  '126': '126 Uigur',
  '127': '127 Ukrainian',
  '128': '128 Urdu',
  '129': '129 Uzbek',
  '130': '130 Vietnamese',
  '131': '131 Volapuk',
  '132': '132 Welsh',
  '133': '133 Wolof',
  '134': '134 Xhosa',
  '135': '135 Yiddish',
  '136': '136 Yoruba',
  '137': '137 Zhuang',
  '138': '138 Zulu',
};

export const ALLIED_PROVIDER_TYPE = {
  '005': 'Doctor of Nursing Practice-DNP',
  '020': 'Acupuncturist',
  '050': 'Addiction Medicine Specialist',
  '051': 'Advanced Practice Registered Nurse',
  '021': 'Alcohol/Drug Counselor',
  '022': 'Audiologist',
  '052': 'Behavioral Analyst',
  '023': 'Biofeedback Technician',
  '053': 'Board Certified Behavioral Analyst',
  '054': 'Board Certified Behavioral Analyst Assistant',
  '024': 'Certified Registered Nurse Anesthetist',
  '025': 'Christian Science Practitioner',
  '026': 'Clinical Nurse Specialist',
  '027': 'Clinical Psychologist',
  '028': 'Clinical Social Worker',
  '029': 'Dietician',
  '061': 'Licensed Mental Health Counselor',
  '030': 'Licensed Practical Nurse',
  '055': 'Licensed Social Worker (APSW, ISW, LCSW)',
  '065': 'Licensed Marriage and Family Therapist (LFMT)',
  '031': 'Licensed Marriage and Family Therapist (LFMT)',
  '032': 'Massage Therapist',
  '056': "Master's Level Counselor",
  '035': 'Midwife',
  '033': 'Naturopath',
  '034': 'Neuropsychologist',
  '036': 'Nurse Midwife',
  '037': 'Nurse Practitioner',
  '062': 'NP-Mental Health',
  '038': 'Nutritionist',
  '039': 'Occupational Therapist',
  '040': 'Optician',
  '041': 'Optometrist',
  '042': 'Pharmacist',
  '043': 'Physical Therapist',
  '044': 'Physician Assistant',
  '063': 'PA-Mental Health',
  '045': 'Professional Counselor',
  '064': 'Mental Health Counselor',
  '057': 'Psychiatrist',
  '058': 'Registered Behavioral Tech',
  '059': 'Registered Dietician',
  '046': 'Registered Nurse',
  '047': 'Registered Nurse First Assistant',
  '048': 'Respiratory Therapist',
  '049': 'Speech Pathologist (SLP)',
  '066': 'Substance Abuse Counselor',
  '067': 'Doctor of Endocrinology',
  '068': 'Doctor of Opthalmology',
  '069': 'Registered Dental Hygienist (RDH)',
  '070': 'Oral and Maxillofacial Pathologist - DDS',
  '071': 'Psychologist',
  '072': 'Clinical Neuropsychologist',
  '073': 'Dental Assistant',
  '074': 'Licensed Independent Social Worker (LISW)',
  '075': 'Medical Assistant',
  '076': 'Independent Social Worker',
  '077': 'Student Nurse Midwife',
  '078': 'Certified Nurse Midwife',
  '079': 'Surgical Tech',
  '080': 'Behavior Technician',
  '081': 'Physical Therapist Assistant',
  '082': 'Occupational Therapist Assistant',
  '083': 'Licensed Master of Social Work (LMSW)',
  '084': 'Adult-Gerontology Acute Care Nurse Practitioner (AGACNP)',
  '085': 'Adult-Gerontology Clinical Nurse Specialist Certification (AGCNS)',
  '086': 'Adult-Gerontology Primary Care Nurse Practitioner (AGPCNP)',
  '087': 'Ambulatory Care Nursing (AMB)',
  '088': 'Cardiac Vascular Nursing Certification (CV)',
  '089': 'Family Nurse Practitioner Certification (FNP)',
  '090': 'Gerontological Nursing Certification (GERO)',
  '091': 'Medical-Surgical Nursing Certification (MEDSURG)',
  '092': 'Psychiatric-Mental Health Nurse Practitioner (PMHNP)',
  '093': 'Psychiatric-Mental Health Nursing Certification (PMH)',
  '094': 'Pain Management Nursing Certification (PMGT)',
  '095': 'Pediatric Nursing Certification (PED)',
  '096': "Nurse's Aide-Long Term Care",
  '097': 'Surgical Assist',
  '869': 'Genetic Counselor',
  '870': 'Health Coach',
};
export const PROVIDER_TYPE = {
  '001': 'Medical Doctor-MD',
  '006': 'Doctor of Chiropractic-DC',
  '002': 'Doctor of Dental Surgery-DDS',
  '003': 'Doctor of Dental Medicine-DMD',
  '007': 'Osteopathic Doctor-DO',
  '004': 'Doctor of Podiatric Medicine-DPM',
  '081': 'Other',
};
export const PROVIDER_TYPE_ABBR = {
  '001': 'MD/DO',
  '006': 'DC',
  '002': 'DDS/DMD',
  '003': 'DDS/DMD',
  '007': 'MD/DO',
  '004': 'DPM',
  '081': 'Other',
};

export const FACILITY_TYPE = {
  '001': 'Healthcare Facility License',
  '002': 'Residental Treatment Center for Children',
  '003': 'Community Mental Health Center',
  '004': 'Outpatient Mental Health Facility',
  '005': 'Psychiatric Hospital',
  '006': 'Behavioral Health Residental Facility',
  '007': 'County Business License',
  '008': 'Bio-Medical Waste',
  '009': 'Healthcare Clinic Exemption',
  '010': 'Joint Commission Accreditation',
  '011': 'Advanced Certification',
  '012': 'CLIA Certificate',
};
export const FACILITY_PERSONAL_TYPE = {
  '001': 'Juvenile State Center',
  '002': 'Residental Treatment Center',
  '003': 'Community Mental Health Center',
  '004': 'Outpatient Mental Health Facility',
  '005': 'Psychiatric Hospital',
  '006': 'Medical Group',
  '007': 'Medical Lab',
  '008': 'Behavioral Health',
  '009': 'IDTF (Independent Diagnostic Testing Facility (Mobile))',
  '010': 'Home Health Agency',
  '011': 'Federally Qualified Health Center - FQHC',
};
export const COUNTRIES = {
  '004': 'Afghanistan',
  '008': 'Albania',
  '012': 'Algeria',
  '016': 'American Samoa',
  '020': 'Andorra',
  '024': 'Angola',
  '660': 'Anguilla',
  '010': 'Antarctica',
  '028': 'Antigua and Barbuda',
  '032': 'Argentina',
  '051': 'Armenia',
  '533': 'Aruba',
  '036': 'Australia',
  '040': 'Austria',
  '031': 'Azerbaijan',
  '044': 'Bahamas',
  '048': 'Bahrain',
  '050': 'Bangladesh',
  '112': 'Belarus',
  '056': 'Belgium',
  '084': 'Belize',
  '204': 'Benin',
  '060': 'Bermuda',
  '064': 'Bhutan',
  '068': 'Bolivia',
  '070': 'Bosnia and Herzegovina',
  '072': 'Botswana',
  '074': 'Bouvet Island',
  '076': 'Brazil',
  '086': 'British Indian Ocean Territory',
  '096': 'Brunei Darussalam',
  '100': 'Bulgaria',
  '854': 'Burkina Faso',
  '108': 'Burundi',
  '116': 'Cambodia',
  '120': 'Cameroon',
  '124': 'Canada',
  '132': 'Cape Verde',
  '136': 'Cayman Islands',
  '140': 'Central African Republic',
  '148': 'Chad',
  '152': 'Chile',
  '156': 'China',
  '162': 'Christmas Island',
  '166': 'Cocos (Keeling) Islands',
  '170': 'Colombia',
  '174': 'Comoros',
  '178': 'Congo',
  '180': 'Congo, Democratic Republic of the',
  '184': 'Cook Islands',
  '188': 'Costa Rica',
  '384': "Cote d'Ivoire",
  '191': 'Croatia',
  '192': 'Cuba',
  '196': 'Cyprus',
  '203': 'Czech Republic',
  '208': 'Denmark',
  '262': 'Djibouti',
  '212': 'Dominica',
  '214': 'Dominican Republic',
  '626': 'East Timor (provisional)',
  '218': 'Ecuador',
  '818': 'Egypt',
  '222': 'El Salvador',
  '226': 'Equatorial Guinea',
  '232': 'Eritrea',
  '233': 'Estonia',
  '231': 'Ethiopia',
  '238': 'Falkland Islands (Malvinas)',
  '234': 'Faroe Islands',
  '242': 'Fiji',
  '246': 'Finland',
  '250': 'France',
  '249': 'France, Metropolitan',
  '254': 'French Guiana',
  '258': 'French Polynesia',
  '260': 'French Southern Territories',
  '266': 'Gabon',
  '270': 'Gambia',
  '268': 'Georgia',
  '276': 'Germany',
  '288': 'Ghana',
  '292': 'Gibraltar',
  '300': 'Greece',
  '304': 'Greenland',
  '308': 'Grenada',
  '312': 'Guadaloupe',
  '316': 'Guam',
  '320': 'Guatemala',
  '324': 'Guinea',
  '624': 'Guinea-Bissau',
  '328': 'Guyana',
  '332': 'Haiti',
  '334': 'Heard Island and McDonald',
  '789': 'Islands',
  '340': 'Honduras',
  '344': 'Hong Kong',
  '348': 'Hungary',
  '352': 'Iceland',
  '356': 'India',
  '360': 'Indonesia',
  '364': 'Iran',
  '368': 'Iraq',
  '372': 'Ireland',
  '376': 'Israel',
  '380': 'Italy',
  '388': 'Jamaica',
  '392': 'Japan',
  '400': 'Jordan',
  '398': 'Kazakhstan',
  '404': 'Kenya',
  '296': 'Kiribati',
  '408': 'Korea, North',
  '410': 'Korea, South',
  '414': 'Kuwait',
  '417': 'Kyrgyzstan',
  '418': 'Laos',
  '428': 'Latvia',
  '422': 'Lebanon',
  '426': 'Lesotho',
  '430': 'Liberia',
  '434': 'Libya',
  '438': 'Liechtenstein',
  '440': 'Lithuania',
  '442': 'Luxembourg',
  '446': 'Macau',
  '807': 'Macedonia',
  '450': 'Madagascar',
  '454': 'Malawi',
  '458': 'Malaysia',
  '462': 'Maldives',
  '466': 'Mali',
  '470': 'Malta',
  '584': 'Marshall Islands',
  '474': 'Martinique',
  '478': 'Mauritania',
  '480': 'Mauritius',
  '175': 'Mayotte',
  '484': 'Mexico',
  '583': 'Micronesia',
  '498': 'Moldova',
  '492': 'Monaco',
  '496': 'Mongolia',
  '500': 'Montserrat',
  '504': 'Morocco',
  '508': 'Mozambique',
  '104': 'Myanmar',
  '516': 'Namibia',
  '520': 'Nauru',
  '524': 'Nepal',
  '528': 'Netherlands',
  '530': 'Netherlands Antilles',
  '540': 'New Caledonia',
  '554': 'New Zealand',
  '558': 'Nicaragua',
  '562': 'Niger',
  '566': 'Nigeria',
  '570': 'Niue',
  '574': 'Norfolk Island',
  '580': 'Northern Mariana Islands',
  '578': 'Norway',
  '512': 'Oman',
  '586': 'Pakistan',
  '585': 'Palau',
  '591': 'Panama',
  '598': 'Papua New Guinea',
  '600': 'Paraguay',
  '604': 'Peru',
  '608': 'Philippines',
  '612': 'Pitcairn',
  '616': 'Poland',
  '620': 'Portugal',
  '630': 'Puerto Rico',
  '634': 'Qatar',
  '638': 'Réunion',
  '642': 'Romania',
  '643': 'Russian Federation',
  '646': 'Rwanda',
  '654': 'Saint Helena',
  '659': 'Saint Kitts and Nevis',
  '662': 'Saint Lucia',
  '666': 'Saint Pierre and Miquelon',
  '670': 'Saint Vincent and the Grenadines',
  '882': 'Samoa',
  '674': 'San Marino',
  '678': 'São Tomé and Príncipe',
  '682': 'Saudi Arabia',
  '683': 'Scotland',
  '686': 'Senegal',
  '381': 'Serbia',
  '690': 'Seychelles',
  '694': 'Sierra Leone',
  '702': 'Singapore',
  '703': 'Slovakia',
  '705': 'Slovenia',
  '090': 'Solomon Islands',
  '706': 'Somalia',
  '710': 'South Africa',
  '239': 'South Georgia and the South',
  'Sandwich Islands': 'Sandwich Islands',
  '724': 'Spain',
  '144': 'Sri Lanka',
  '736': 'Sudan',
  '740': 'Suriname',
  '744': 'Svalbard and Jan Mayen',
  '748': 'Swaziland',
  '752': 'Sweden',
  '756': 'Switzerland',
  '760': 'Syria',
  '158': 'Taiwan',
  '762': 'Tajikistan',
  '834': 'Tanzania',
  '764': 'Thailand',
  '768': 'Togo',
  '772': 'Tokelau',
  '776': 'Tonga',
  '780': 'Trinidad and Tobago',
  '788': 'Tunisia',
  '792': 'Turkey795 Turkmenistan',
  '796': 'Turks and Caicos Islands',
  '798': 'Tuvalu',
  '800': 'Uganda',
  '804': 'Ukraine',
  '784': 'United Arab Emirates',
  '826': 'United Kingdom',
  '840': 'United States',
  '581': 'U.S. Minor Outlying Islands',
  '858': 'Uruguay',
  '860': 'Uzbekistan',
  '548': 'Vanuatu',
  '336': 'Vatican City State (Holy See)',
  '862': 'Venezuela',
  '704': 'Viet Nam',
  '092': 'Virgin Islands, British',
  '850': 'Virgin Islands, U.S.',
  '876': 'Wallis and Fortuna Islands',
  '732': 'Western Sahara (provisional)',
  '887': 'Yemen',
  '891': 'Yugoslavia',
  '894': 'Zambia',
  '716': 'Zimbabwe',
};

export const QUESTIONS = [
  'Has your license, registration or certification to practice in your profession, ever been voluntarily or involuntarily relinquished, denied, suspended, revoked, restricted, or have you ever been subject to a fine, reprimand, consent order, probation or any conditions or limitations by any state or professional licensing, registration or certification board?',
  'Has there been any challenge to your licensure, registration or certification?',
  'Have your clinical privileges or medical staff membership at any hospital or healthcare institution, voluntarily or involuntarily, ever been denied, suspended, revoked, restricted, denied renewal or subject to probationary or to other disciplinary conditions (for reasons other than non-completion of medical record when quality of care was not adversely affected) or have proceedings toward any of those ends been instituted or recommended by any hospital or healthcare institution, medical staff or committee, or governing board?',
  'Have you voluntarily or involuntarily surrendered, limited your privileges or not reapplied for privileges while under investigation?',
  'Have you ever been terminated for cause or not renewed for cause from participation, or been subject to any disciplinary action, by any managed care organizations (including HMOs, PPOs, or provider organizations such as IPAs, PHOs)?',
  'Were you ever placed on probation, disciplined, formally reprimanded, suspended or asked to resign during an internship, residency, fellowship, preceptorship or another clinical education program? If you are currently in a training program, have you been placed on probation, disciplined, formally reprimanded, suspended or asked to resign?',
  'Have you ever, while under investigation or to avoid an investigation, voluntarily withdrawn or prematurely terminated your status as a student or employee in an internship, residency, fellowship, preceptorship, or another clinical education program?',
  'Have any of your board certifications or eligibility ever been revoked?',
  'Have you ever chosen not to re-certify or voluntarily surrendered your board certification(s) while under investigation?',
  'Have your Federal DEA and/or State Controlled Dangerous Substances (CDS) certificate(s) or authorization(s) ever been challenged, denied, suspended, revoked, restricted, denied renewal, or voluntarily or involuntarily relinquished?',
  'Have you ever been disciplined, excluded from, debarred, suspended, reprimanded, sanctioned, censured, disqualified or otherwise restricted in regard to participation in the Medicare or Medicaid program, or in regard to other federal or state governmental healthcare plans or programs?',
  'Are you currently the subject of an investigation by any hospital, licensing authority, DEA or CDS authorizing entities, education or training program, Medicare or Medicaid program, or any other private, federal or state health program or a defendant in any civil action that is reasonably related to your qualifications, competence, functions, or duties as a medical professional for alleged fraud, an act of violence, child abuse or a sexual offense or sexual misconduct?',
  'To your knowledge, has information pertaining to you ever been reported to the National Practitioner Data Bank or Healthcare Integrity and Protection Data Bank?',
  'Have you ever received sanctions from or are you currently the subject of an investigation by any regulatory agencies (e.g., CLIA, OSHA, etc.)?',
  'Have you ever been convicted of, pled guilty to, pled nolo contendere to, sanctioned, reprimanded, restricted, disciplined or resigned in exchange for no investigation or adverse action within the last ten years for sexual harassment or other illegal misconduct?',
  'Are you currently being investigated or have you ever been sanctioned, reprimanded, or cautioned by a military hospital, facility, or agency, or voluntarily terminated or resigned while under investigation or in exchange for no investigation by a hospital or healthcare facility of any military agency?',
  'Has your professional liability coverage ever been canceled, restricted, declined or not renewed by the carrier based on your individual liability history?',
  'Have you ever been assessed a surcharge, or rate by your professional liability insurance carrier, based on your individual liability history?',
  'Have you ever been convicted of, pled guilty to, or pled nolo contendere to any felony?',
  'In the past ten years have you been convicted of, pled guilty to, or pled nolo contendere to any misdemeanor (excluding minor traffic violations) or been found liable or responsible for any civil offense that is reasonably related to your qualifications, competence, functions, or duties as a medical professional, or for fraud, an act of violence, child abuse or a sexual offense or sexual misconduct?',
  'Have you ever been court-martialed for actions related to your duties as a medical professional?',
  'Are you currently engaged in the illegal use of drugs? ("Currently" means sufficiently recent to justify a reasonable belief that the use of drugs may have an ongoing impact on one\'s ability to practice medicine. It is not limited to the day of, or within a matter of days or weeks before the date of application, rather that it has occurred recently enough to indicate the individual is actively engaged in such conduct. "Illegal use of drugs" refers to drugs whose possession or distribution is unlawful under the Controlled Substances Act, 21 U.S.C. § 812.22. It "does not include the use of a drug taken under supervision by a licensed health care professional, or other uses authorized by the Controlled Substances Act or other provision of Federal law." The term does include, however, the unlawful use of prescription controlled substances.)',
  'Do you use any chemical substances that would in any way impair or limit your ability to practice medicine and perform the functions of your job with reasonable skill and safety?',
  'Do you have any reason to believe that you would pose a risk to the safety or well being of your patients?',
  'Are you unable to perform the essential functions of a practitioner in your area of practice even with reasonable accommodation?',
  'Have you had any professional liability actions (pending, settled, arbitrated, mediated or litigated) within the past 10 years?',
];
export const QuestionsModel = [
  {
    Question:
      'Has your license, registration or certification to practice in your profession, ever been voluntarily or involuntarily relinquished, denied, suspended, revoked, restricted, or have you ever been subject to a fine, reprimand, consent order, probation or any conditions or limitations by any state or professional licensing, registration or certification board?',
    Answer: 'isFined',
    Note: 'noteFine',
  },
  {
    Question:
      'Has there been any challenge to your licensure, registration or certification?',
    Answer: 'isChallenged',
    Note: 'noteChallenged',
  },
  {
    Question:
      'Have your clinical privileges or medical staff membership at any hospital or healthcare institution, voluntarily or involuntarily, ever been denied, suspended, revoked, restricted, denied renewal or subject to probationary or to other disciplinary conditions (for reasons other than non-completion of medical record when quality of care was not adversely affected) or have proceedings toward any of those ends been instituted or recommended by any hospital or healthcare institution, medical staff or committee, or governing board?',
    Answer: 'isClinicalPrivileges',
    Note: 'noteClinicalPrivileges',
  },
  {
    Question:
      'Have you voluntarily or involuntarily surrendered, limited your privileges or not reapplied for privileges while under investigation?',
    Answer: 'isSurrendered',
    Note: 'noteSurrendered',
  },
  {
    Question:
      'Have you ever been terminated for cause or not renewed for cause from participation, or been subject to any disciplinary action, by any managed care organizations (including HMOs, PPOs, or provider organizations such as IPAs, PHOs)?',
    Answer: 'isTerminated',
    Note: 'noteTerminated',
  },
  {
    Question:
      'Were you ever placed on probation, disciplined, formally reprimanded, suspended or asked to resign during an internship, residency, fellowship, preceptorship or another clinical education program? If you are currently in a training program, have you been placed on probation, disciplined, formally reprimanded, suspended or asked to resign?',
    Answer: 'isPlaced',
    Note: 'notePlaced',
  },
  {
    Question:
      'Have you ever, while under investigation or to avoid an investigation, voluntarily withdrawn or prematurely terminated your status as a student or employee in an internship, residency, fellowship, preceptorship, or another clinical education program?',
    Answer: 'isInvestigation',
    Note: 'noteInvestigation',
  },
  {
    Question:
      'Have any of your board certifications or eligibility ever been revoked?',
    Answer: 'isBoardCertification',
    Note: 'noteBoardCertification',
  },
  {
    Question:
      'Have you ever chosen not to re-certify or voluntarily surrendered your board certification(s) while under investigation?',
    Answer: 'isReCertify',
    Note: 'noteReCertify',
  },
  {
    Question:
      'Have your Federal DEA and/or State Controlled Dangerous Substances (CDS) certificate(s) or authorization(s) ever been challenged, denied, suspended, revoked, restricted, denied renewal, or voluntarily or involuntarily relinquished?',
    Answer: 'isFederalDEA',
    Note: 'noteFederalDEA',
  },
  {
    Question:
      'Have you ever been disciplined, excluded from, debarred, suspended, reprimanded, sanctioned, censured, disqualified or otherwise restricted in regard to participation in the Medicare or Medicaid program, or in regard to other federal or state governmental healthcare plans or programs?',
    Answer: 'isDisciplined',
    Note: 'noteDisciplined',
  },
  {
    Question:
      'Are you currently the subject of an investigation by any hospital, licensing authority, DEA or CDS authorizing entities, education or training program, Medicare or Medicaid program, or any other private, federal or state health program or a defendant in any civil action that is reasonably related to your qualifications, competence, functions, or duties as a medical professional for alleged fraud, an act of violence, child abuse or a sexual offense or sexual misconduct?',
    Answer: 'hasSubjectInvestigation',
    Note: 'noteSubjectInvestigation',
  },
  {
    Question:
      'To your knowledge, has information pertaining to you ever been reported to the National Practitioner Data Bank or Healthcare Integrity and Protection Data Bank?',
    Answer: 'hasInformation',
    Note: 'noteInformation',
  },
  {
    Question:
      'Have you ever received sanctions from or are you currently the subject of an investigation by any regulatory agencies (e.g., CLIA, OSHA, etc.)?',
    Answer: 'isSanctions',
    Note: 'noteSanctions',
  },
  {
    Question:
      'Have you ever been convicted of, pled guilty to, pled nolo contendere to, sanctioned, reprimanded, restricted, disciplined or resigned in exchange for no investigation or adverse action within the last ten years for sexual harassment or other illegal misconduct?',
    Answer: 'isConvicted',
    Note: 'noteConvited',
  },
  {
    Question:
      'Are you currently being investigated or have you ever been sanctioned, reprimanded, or cautioned by a military hospital, facility, or agency, or voluntarily terminated or resigned while under investigation or in exchange for no investigation by a hospital or healthcare facility of any military agency?',
    Answer: 'isInvestigated',
    Note: 'noteInvestigated',
  },
  {
    Question:
      'Has your professional liability coverage ever been canceled, restricted, declined or not renewed by the carrier based on your individual liability history?',
    Answer: 'hasProfessionalLiability',
    Note: 'noteProfessionalLiability',
  },
  {
    Question:
      'Have you ever been assessed a surcharge, or rate by your professional liability insurance carrier, based on your individual liability history?',
    Answer: 'hasAssessed',
    Note: 'noteAssessed',
  },
  {
    Question:
      'Have you ever been convicted of, pled guilty to, or pled nolo contendere to any felony?',
    Answer: 'hasConvictedPled',
    Note: 'noteConvictedPled',
  },
  {
    Question:
      'In the past ten years have you been convicted of, pled guilty to, or pled nolo contendere to any misdemeanor (excluding minor traffic violations) or been found liable or responsible for any civil offense that is reasonably related to your qualifications, competence, functions, or duties as a medical professional, or for fraud, an act of violence, child abuse or a sexual offense or sexual misconduct?',
    Answer: 'hasConvictedPledTenYears',
    Note: 'noteConvictedPledTenYears',
  },
  {
    Question:
      'Have you ever been court-martialed for actions related to your duties as a medical professional?',
    Answer: 'hasCourtMartialed',
    Note: 'noteCourtMartialed',
  },
  {
    Question:
      'Are you currently engaged in the illegal use of drugs? ("Currently" means sufficiently recent to justify a reasonable belief that the use of drugs may have an ongoing impact on one\'s ability to practice medicine. It is not limited to the day of, or within a matter of days or weeks before the date of application, rather that it has occurred recently enough to indicate the individual is actively engaged in such conduct. "Illegal use of drugs" refers to drugs whose possession or distribution is unlawful under the Controlled Substances Act, 21 U.S.C. § 812.22. It "does not include the use of a drug taken under supervision by a licensed health care professional, or other uses authorized by the Controlled Substances Act or other provision of Federal law." The term does include, however, the unlawful use of prescription controlled substances.)',
    Answer: 'hasCurrentlyEngaged',
    Note: 'noteCurrentlyEngaged',
  },
  {
    Question:
      'Do you use any chemical substances that would in any way impair or limit your ability to practice medicine and perform the functions of your job with reasonable skill and safety?',
    Answer: 'hasChemicalSubstance',
    Note: 'noteChemicalSubstance',
  },
  {
    Question:
      'Do you have any reason to believe that you would pose a risk to the safety or well being of your patients?',
    Answer: 'isBelievePose',
    Note: 'noteBelievePose',
  },
  {
    Question:
      'Are you unable to perform the essential functions of a practitioner in your area of practice even with reasonable accommodation?',
    Answer: 'isUnableFunctions',
    Note: 'noteUnableFunctions',
  },
  {
    Question:
      'Have you had any professional liability actions (pending, settled, arbitrated, mediated or litigated) within the past 10 years?',
    Answer: 'hasLiabilityPending',
    Form: 'liabilityPending',
  },
];

export const STATES_NAMES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'Delaware',
  DE: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const ALLIED_PROVIDER = {
  'N/A': 'N/A',
  '501': 'Acupuncturist',
  '502': 'Other, Not Listed',
  '503': 'Audiologist',
  '504': 'Audiologist, Assistive Tech Practitioner',
  '505': 'Audiologist, Assistive Technology Supplier',
  '506': 'Speech-Language Pathologist',
  '513': 'Counselor',
  '514': 'Counselor, Addiction',
  '515': 'Counselor, Mental Health',
  '516': 'Counselor, Professional',
  '517': 'Marriage & Family Therapist',
  '531': 'Christian Science Practitioner',
  '533': 'Dietitian, Registered',
  '534': 'Dietitian, Registered, Nutrition, Pediatric',
  '535': 'Dietitian, Registered, Nutrition, Renal',
  '536': 'Dietitian, Registered, Nutrition, Metabolic',
  '537': 'Nutritionist',
  '538': 'Nutritionist, Nutrition, Education',
  '547': 'Massage Therapist',
  '549': 'Midwife, Certified',
  '551': 'Naturopath',
  '553': 'Neuropsychologist',
  '555': 'Occupational Therapist',
  '556': 'Occupational Therapist, Ergonomics',
  '557': 'Occupational Therapist, Hand',
  '558': 'Occupational Therapist, Human Factors',
  '559': 'Occupational Therapist, Neurorehabilitation',
  '560': 'Occupational Therapist, Pediatrics',
  '561': 'Occupational Therapist, Rehab, Driver',
  '563': 'Optician',
  '565': 'Optometrist',
  '566': 'Optometrist, Corneal and Contact Mngmt',
  '567': 'Optometrist, Low Vision Rehabilitation',
  '568': 'Optometrist, Pediatrics',
  '569': 'Optometrist, Sports Vision',
  '570': 'Optometrist, Vision Therapy',
  '571': 'Optometrist, Occupational Vision',
  '573': 'Pharmacist',
  '574': 'Pharmacist, General Practice',
  '575': 'Pharmacist, Nuclear Pharmacy',
  '576': 'Pharmacist, Nutrition Support',
  '577': 'Pharmacist, Pharmacotherapy',
  '578': 'Pharmacist, Psychopharmacy',
  '580': 'Physical Therapist',
  '581': 'Physical Therapist, Cardiopulmonary',
  '582': 'Physical Therapist, Ergonomics',
  '583': 'Physical Therapist, Electrophysio, Clinical',
  '584': 'Physical Therapist, Geriatrics',
  '585': 'Physical Therapist, Hand',
  '586': 'Physical Therapist, Human Factors',
  '587': 'Physical Therapist, Neurology',
  '588': 'Physical Therapist, Pediatrics',
  '589': 'Physical Therapist, Sports',
  '590': 'Physical Therapist, Orthopedic',
  '592': 'Physician Assistant',
  '593': 'Physician Assistant, Medical',
  '594': 'Physician Assistant, Surgical',
  '596': 'Psychologist',
  '597': 'Psychologist, Addiction',
  '598': 'Psychologist, Adult Development & Aging',
  '599': 'Psychologist, Behavioral',
  '600': 'Psychologist, Clinical',
  '601': 'Psychologist, Counseling',
  '602': 'Psychologist, Child, Youth & Family',
  '603': 'Psychologist, Educational',
  '604': 'Psychologist, Exercise & Sports',
  '605': 'Psychologist, Family',
  '606': 'Psychologist, Forensic',
  '607': 'Psychologist, Health',
  '608': 'Psychologist, Men & Masculinity',
  '609': 'Psychologist, Mental Ret & Dev Disabilities',
  '610': 'Psychologist, Psychoanalysis',
  '611': 'Psychologist, Psychotherapy',
  '612': 'Psychologist, Psychotherapy, Group',
  '613': 'Psychologist, Rehabilitation',
  '614': 'Psychologist, School',
  '615': 'Psychologist, Women',
  '617': 'Respiratory Therapist, Certified',
  '618': 'Respiratory Therapist, Certified, Critical Care',
  '619': 'Respiratory Therapist, Certified, Emerg Care',
  '620': 'Respiratory Therapist, Certified, Educational',
  '621': 'Respiratory Ther, Certified, Geriatric Care',
  '622': 'Respiratory Therapist, Certified, Gen Care',
  '623': 'Respiratory Ther, Certified, Home Health',
  '624': 'Resp Ther, Certified, Pulmonary Diagnostics',
  '625': 'Resp Ther, Certified, Pulm Rehabilitation',
  '626': 'Resp Ther, Certified, Pulm Function Tech',
  '627': 'Resp Ther, Certified, Palliatiative/Hospice',
  '628': 'Respiratory Ther, Certified, Neonatal/Peds',
  '629': 'Respiratory Ther, Certified, Patient Transport',
  '630': 'Resp Therapist, Certified, SNF/Subacute Care',
  '631': 'Respiratory Therapist, Registered',
  '632': 'Resp Therapist, Registered, Critical Care',
  '633': 'Resp Therapist, Registered, Emergency Care',
  '634': 'Resp Therapist, Registered, Educational',
  '635': 'Resp Therapist, Registered, Geriatric Care',
  '636': 'Resp Therapist, Registered, General Care',
  '637': 'Resp Therapist, Registered, Home Health',
  '638': 'Resp Ther, Registered, Pulm Diagnostics',
  '639': 'Resp Ther, Registered, Pulm Rehabilitation',
  '640': 'Resp Ther, Registered, Pulm Func Tech',
  '641': 'Resp Ther, Registered, Palliative/Hospice',
  '642': 'Resp Ther, Registered, Neonatal/Pediatrics',
  '643': 'Resp Therapist, Registered, Patient Transport',
  '644': 'Resp Ther, Registered, SNF/Subacute Care',
  '646': 'Social Worker, Clinical',
  '648': 'Specialist/Technologist, Other, Biomed Eng',
  '649': 'Technician, Other, Biomedical Engineering',
  '651': 'Licensed Practical Nurse',
  '652': 'Midwife, Certified Nurse',
  '653': 'Nurse Anesthetist, Certified Registered',
  '654': 'Nurse Practitioner',
  '655': 'Nurse Practitioner, Acute Care',
  '656': 'Nurse Practitioner, Adult Health',
  '657': 'Nurse Practitioner, Critical Care Medicine',
  '658': 'Nurse Practitioner, Community Health',
  '659': 'Nurse Practitioner, Family',
  '660': 'Nurse Practitioner, Gerontology',
  '661': 'Nurse Practitioner, Neonatal',
  '662': 'Nurse Practitioner, Neonatal, Critical Care',
  '663': 'Nurse Practitioner, Pediatrics',
  '664': 'Nurse Practitioner, Pediatrics, Critical Care',
  '665': 'Nurse Practitioner, Psych/Mental Health',
  '666': 'Nurse Practitioner, Perinatal',
  '667': 'Nurse Practitioner, Primary Care',
  '668': 'Nurse Practitioner, School',
  '669': 'Nurse Practitioner, Women’s Health',
  '670': 'Nurse Practitioner, Obstetrics & Gynecology',
  '671': 'Nurse Practitioner, Occupational Health',
  '672': 'Registered Nurse',
  '673': 'Registered Nurse, Addiction',
  '674': 'Registered Nurse, Administrator',
  '675': 'Registered Nurse, Critical Care Medicine',
  '676': 'Registered Nurse, Case Management',
  '677': 'Registered Nurse, College Health',
  '678': 'Registered Nurse, Community Health',
  '679': 'Registered Nurse, Cont Educ/Staff Dev',
  '680': 'Registered Nurse, Continence Care',
  '681': 'Registered Nurse, Cardiac Rehabilitation',
  '682': 'Registered Nurse, Diabetes Educator',
  '683': 'Registered Nurse, Dialysis, Peritoneal',
  '684': 'Registered Nurse, Emergency',
  '685': 'Registered Nurse, Enterostomal Therapy',
  '686': 'Registered Nurse, Flight',
  '687': 'Registered Nurse, General Practice',
  '688': 'Registered Nurse, Gastroenterology',
  '689': 'Registered Nurse, Gerontology',
  '690': 'Registered Nurse, Home Health',
  '691': 'Registered Nurse, Hemodialysis',
  '692': 'Registered Nurse, Hospice',
  '693': 'Registered Nurse, Infusion Therapy',
  '694': 'Registered Nurse, Infection Control',
  '695': 'Registered Nurse, Lactation Consultant',
  '696': 'Registered Nurse, Maternal Newborn',
  '697': 'Registered Nurse, Medical-Surgical',
  '698': 'Registered Nurse, Nurse Massage Therapist',
  '699': 'Registered Nurse, Neonatal Intensive Care',
  '700': 'Registered Nurse, Neonatal, Low-Risk',
  '701': 'Registered Nurse, Nephrology',
  '702': 'Registered Nurse, Neuroscience',
  '703': 'Registered Nurse, Nutrition Support',
  '704': 'Registered Nurse, Pain Management',
  '705': 'Registered Nurse, Pediatrics',
  '706': 'Registered Nurse, Pediatric Oncology',
  '707': 'Registered Nurse, Psych-Child & Adolescent',
  '708': 'Registered Nurse, Psych/Mental Health',
  '709': 'Registered Nurse, Psych/Ment Health-Adult',
  '710': 'Registered Nurse, Perinatal',
  '711': 'Registered Nurse, Ambulatory Care',
  '712': 'Registered Nurse, Rehabilitation',
  '713': 'Registered Nurse, Reprod Endocrin/Infert',
  '714': 'Registered Nurse, Plastic Surgery',
  '715': 'Registered Nurse, School',
  '716': 'Registered Nurse, Urology',
  '717': 'Registered Nurse, Wound Care',
  '718': 'Registered Nurse, Women’s Health, Ambul.',
  '719': 'Registered Nurse, Obstetric, High-Risk',
  '720': 'Registered Nurse, Obstetric, Inpatient',
  '721': 'Registered Nurse, Occupational Health',
  '722': 'Registered Nurse, Oncology',
  '723': 'Reg Nurse, Otorhinolaryngology/Head-Neck',
  '724': 'Registered Nurse, Orthopedic',
  '725': 'Registered Nurse, Ophthalmic',
  '726': 'Registered Nurse, Ostomy Care',
  '727': 'Clinical Nurse Specialist',
  '728': 'Clinical Nurse Specialist, Acute Care',
  '729': 'Clinical Nurse Specialist, Adult Health',
  '730': 'Clinical Nurse Specialist, Chronic Care',
  '731': 'Clin Nurse Spec, CommHealth/PubHealth',
  '732': 'Clinical Nurse Specialist, Crit Care Medicine',
  '733': 'Clinical Nurse Specialist, Emergency',
  '734': 'Clinical Nurse Specialist, Ethics',
  '735': 'Clinical Nurse Specialist, Family Health',
  '736': 'Clinical Nurse Specialist, Gerontology',
  '737': 'Clinical Nurse Specialist, Holistic',
  '738': 'Clinical Nurse Specialist, Home Health',
  '739': 'Clinical Nurse Specialist, Informatics',
  '740': 'Clinical Nurse Specialist, Long-Term Care',
  '741': 'Clinical Nurse Specialist, Medical-Surgical',
  '742': 'Clinical Nurse Specialist, Neonatal',
  '743': 'Clinical Nurse Specialist, Neuroscience',
  '744': 'Clinical Nurse Specialist, Occ Health',
  '745': 'Clinical Nurse Specialist, Oncology',
  '746': 'Clinical Nurse Specialist, Onc/Pediatrics',
  '747': 'Clinical Nurse Specialist, Pediatrics',
  '748': 'Clinical Nurse Specialist, Perinatal',
  '749': 'Clinical Nurse Specialist, Perioperative',
  '750': 'Clinical Nurse Specialist, Psych/Ment Health',
  '751': 'Clinical Nurse Specialist, Psych/Ment Adult',
  '752': 'Clinical Nurse Spec, Psych Child/Adolescent',
  '753': 'Clinical Nurse Spec, Psych Child & Family',
  '754': 'Clinical Nurse Spec, Psych Chronically Ill',
  '755': 'Clinical Nurse Spec, Community',
  '756': 'Clinical Nurse Spec, Geropsychiatric',
  '757': 'Clinical Nurse Specialist, Rehabilitation',
  '758': 'Clinical Nurse Specialist, Transplantation',
  '759': 'Clinical Nurse Specialist, School',
  '760': 'Clinical Nurse Specialist, Women’s Health',
  '761': 'ARPN',
  '762': 'NP',
};

export const STATUS_CODE_LICENSE = {
  '001': '001 Active',
  '002': '002 Canceled',
  '003': '003 Denied',
  '004': '004 Expired',
  '005': '005 Inactive',
  '006': '006 Lapsed',
  '007': '007 Limited',
  '008': '008 Pending',
  '009': '009 Probation',
  '010': '010 Provisional',
  '011': '011 Restricted',
  '012': '012 Revoked',
  '013': '013 Suspended',
  '014': '014 Surrendered',
  '015': '015 Temporary',
  '016': '016 Terminated',
  '017': '017 Time Limited',
  '018': '018 Unrestricted',
  '019': '019 Other',
};

export const MD_DO = {
  'N/A': 'N/A',
  '100': 'Psychiatry & Neurology, Neurology',
  '112': 'Preventive Medicine, Occupational Medicine',
  '114': 'Preventive Med/Occupational Environ',
  '119': 'Orthopedic',
  '126': 'Anesthesiology, Pain Medicine',
  '173': 'Radiology, Diagnostic Radiology',
  '225': 'General Practice',
  '235': 'Anesthesiology, Addiction Medicine',
  '236': 'Internal Medicine, Adolescent Medicine',
  '237': 'Family Practice, Adolescent Medicine',
  '239': 'Pediatrics, Adolescent Medicine',
  '242': 'Preventive Medicine, Aerospace Medicine',
  '244': 'Pediatrics',
  '246': 'Allergy & Immunology, Allergy',
  '247': 'Allergy & Immunology',
  '248': 'Internal Medicine, Allergy & Immunology',
  '249': 'Anesthesiology',
  '250': 'Path, Blood Banking & Transfusion Med',
  '252': 'Radiology, Body Imaging',
  '253': 'Internal Med, Clin Cardiac Electrophysiology',
  '255': 'Internal Medicine, Cardiovascular Disease',
  '257': 'Internal Medicine, Critical Care Medicine',
  '258': 'Anesthesiology, Critical Care Medicine',
  '260': 'Obstetrics & Gyn, Critical Care Medicine',
  '261': 'Medical Genetics, Clinical Cytogenetic',
  '262': 'Pathology, Cytopathology',
  '263': 'Dermatology',
  '264': 'Dermatology, MOHS-Micrographic Surgery',
  '265': 'Pathology, Dermatopathology',
  '266': 'Dermatology, Dermatopathology',
  '267': 'Int Med, Endocrin, Diabetes/Metabolism',
  '268': 'Emergency Medicine',
  '271': 'Obstetrics & Gyn, Reprod Endocrinology',
  '272': 'Family Practice',
  '273': 'Pathology, Forensic Pathology',
  '274': 'Psychiatry & Neurology, Forensic Psych',
  '275': 'Internal Medicine, Gastroenterology',
  '277': 'Medical Genetics, Clinical Genetics (M.D.)',
  '278': 'Medical Genetics, Clin Biochemical Genetics',
  '280': 'Medical Genetics, Clin Molecular Genetics',
  '282': 'Family Practice, Geriatric Medicine',
  '285': 'Internal Medicine, Geriatric Medicine',
  '286': 'Obstetrics & Gynecology, Gynecology',
  '287': 'Internal Medicine, Hematology',
  '288': 'Internal Medicine, Hematology & Oncology',
  '290': 'Pathology, Hematology',
  '291': 'Allergy & Immun, Clinical & Lab',
  '292': 'Derm, Clin & Lab Derm Immunology',
  '294': 'Internal Med, Clinical & Lab Immunology',
  '295': 'Pediatrics, Clinical & Lab Immunology',
  '298': 'Pathology, Immunopathology',
  '299': 'Internal Medicine, Infectious Disease',
  '301': 'Internal Medicine',
  '302': 'Pathology, Clinical Path/Lab Medicine',
  '303': 'Obstetrics & Gyn, Maternal/Fetal Med',
  '305': 'Pathology, Medical Microbiology',
  '306': 'Neonatal-Perinatal Medicine',
  '308': 'Neopathology',
  '309': 'Internal Medicine, Nephrology',
  '311': 'Psych & Neuro, Neuro/Qualif in Child Neuro',
  '312': 'Pathology, Neuropathology',
  '313': 'Psychiatry & Neuro, Clin Neurophysiology',
  '314': 'Radiology, Neuroradiology',
  '315': 'Nuclear Medicine, Nuclear Cardiology',
  '316': 'Nuclear Med, Nuclear Imaging & Therapy',
  '317': 'Nuclear Medicine',
  '318': 'Nuclear Medicine, In Vivo & In Vitro',
  '319': 'Radiology, Nuclear Radiology',
  '320': 'Obstetrics & Gynecology, Obstetrics',
  '321': 'Obstetrics & Gynecology',
  '325': 'Internal Medicine, Medical Oncology',
  '326': 'Obstetrics & Gyn, Gynecologic Oncology',
  '328': 'Ophthalmology',
  '330': 'Neuromusculoskeletal Medicine & OMM',
  '331': 'Otolaryngology',
  '332': 'Otolaryngology, Otology & Neurotology',
  '337': 'Pain Medicine',
  '338': 'Pathology, Anatomic Pathology',
  '340': 'Pathology, Anatomic Path & Clin Pathology',
  '344': 'Pathology, Chemical Pathology',
  '345': 'Pediatrics, Ped Allergy & Immunology',
  '346': 'Pediatrics, Pediatric Cardiology',
  '347': 'Pediatrics, Pediatric Critical Care Medicine',
  '348': 'Emergency Med, Pediatric Emerg Medicine',
  '349': 'Pediatrics, Pediatric Endocrinology',
  '350': 'Pediatrics, Pediatric Gastroenterology',
  '351': 'Pediatrics, Pediatric HematologyOncology',
  '352': 'Pediatrics, Pediatric Infectious Diseases',
  '354': 'Pediatrics, Medical Toxicology',
  '355': 'Pediatrics, Pediatric Nephrology',
  '356': 'Pediatrics, Neurodevelopmental Disabilities',
  '357': 'Otolaryngology, Pediatric Otolaryngology',
  '358': 'Pathology, Pediatric Pathology',
  '359': 'Pediatrics, Pediatric Pulmonology',
  '360': 'Radiology, Pediatric Radiology',
  '361': 'Pediatrics, Pediatric Rheumatology',
  '363': 'Clinical Pharmacology',
  '365': 'Physical Medicine & Rehabilitation',
  '366': 'Public Health & General Preventive Med',
  '367': 'Colon & Rectal Surgery',
  '368': 'Psychiatry & Neurology, Psychiatry',
  '370': 'Psychiatry & Neurology, Addiction Med',
  '371': 'Psychiatry & Neuro, Child & Adolescent',
  '373': 'Psychiatry & Neurology, Geriatric Psychiatry',
  '378': 'Internal Medicine, Pulmonary Disease',
  '380': 'Radiology, Radiation Oncology',
  '381': 'Radiology, Therapeutic Radiology',
  '384': 'Radiology, Vascular & Interv Radiology',
  '389': 'Physical Med & Rehab, Ped Rehab Med',
  '390': 'Internal Medicine, Rheumatology',
  '391': 'Facial Plastic Surgery',
  '395': 'Emergency Medicine, Sports Medicine',
  '396': 'Family Practice, Sports Medicine',
  '397': 'Internal Medicine, Sports Medicine',
  '398': 'Pediatrics, Sports Medicine',
  '399': 'Surgery',
  '400': 'Surgery, Vascular Surgery',
  '405': 'Surgery, Surgery of the Hand',
  '406': 'Orthopaedic Surgery, Hand Surgery',
  '407': 'Plastic Surgery, Surgery of the Hand',
  '409': 'Neurological Surgery',
  '411': 'Orthopaedic Surgery',
  '412': 'Orthopaedic Surgery, Adult Reconstructive',
  '413': 'Surgery, Surgical Oncology',
  '415': 'Orthopaedic Surgery, Ortho Surg- Spine',
  '416': 'Orthopaedic Surgery, Orthopaedic Trauma',
  '417': 'Otolaryngology, Plastic Surgery-Head/Neck',
  '418': 'Surgery, Pediatric Surgery',
  '419': 'Plastic Surgery',
  '420': 'Surgery, Plastic and Recon Surgery',
  '421': 'Thoracic Surgery (Cardiothoracic Vasc Surg)',
  '423': 'Surgery, Trauma Surgery',
  '424': 'Urology',
  '425': 'Surgery, Surgical Critical Care',
  '427': 'Emergency Medicine, Medical Toxicology',
  '429': 'Preventive Medicine, Medical Toxicology',
  '430': 'Radiology, Diagnostic Ultrasound',
  '431': 'Preventive Med, Undersea and Hyperbaric',
  '433': 'Laboratories, Clinical Medical Laboratory',
  '434': 'Supplier',
  '440': 'Neuromusculoskeletal Med, Sports Med',
  '441': 'Oral & Maxillofacial Surgery',
  '442': 'Transplant Surgery',
  '443': 'Dermatology, Pediatric Dermatology',
  '444': 'Dermatology, Dermatological Surgery',
  '445': 'Emergency Medicine, Emergency Med Serv',
  '446': 'Emergency Med, Undersea and Hyperbaric',
  '447': 'Family Practice, Addiction Medicine',
  '448': 'Family Practice, Adult Medicine',
  '449': 'Internal Medicine, Addiction Medicine',
  '450': 'Internal Medicine, Hepatology',
  '451': 'Internal Medicine, Interventional Cardiology',
  '453': 'Internal Medicine, (MRI)',
  '454': 'Medical Genetics, Ph.D. Medical Genetics',
  '455': 'Medical Genetics, Molecular Gen Pathology',
  '456': 'Orthopaedic Surgery, Foot and Ankle',
  '457': 'Orthopaedic Surgery, Sports Medicine',
  '458': 'Otolaryngology, Otolaryngic Allergy',
  '459': 'Otolaryngology, Otolaryn/ Facial Plast Surg',
  '461': 'Path, Molecular Genetic Pathology',
  '462': 'Pediatrics, Develop–Behavioral Pediatrics',
  '463': 'Pediatrics, Pediatric Emergency Medicine',
  '466': 'Physical Med & Rehab, Spinal Cord Injury',
  '468': 'Physical Med & Rehabilitation, Pain Med',
  '469': 'Physical Med & Rehab, Sports Medicine',
  '470': 'Plastic Surgery, Head and Neck',
  '471': 'Preventive Medicine, Sports Medicine',
  '472': 'Psychiatry & Neuro, Neurodevelop Disab',
  '473': 'Psychiatry & Neurology, Addiction Psych',
  '474': 'Psychiatry & Neurology, Pain Medicine',
  '475': 'Psychiatry & Neurology, Sports Medicine',
  '476': 'Psychiatry & Neurology, Vascular Neurology',
  '477': 'Radiology, Radiological Physics',
  '479': 'Hospitalist',
  '480': 'Pain Medicine, Interventional Pain Medicine',
  '481': 'Legal Medicine',
  '763': 'Anesthesiology, Hospice and Palliatice Medicine',
  '764': 'Anesthesiology, Neurocritical Care',
  '765': 'Anesthesiology, Pediatric Anesthesiology',
  '766': 'Anesthesiology, Sleep Medicine',
  '767': 'Dermatology, Clinical & Laboratory Dermatological Immunology',
  '768': 'Dermatology,Micrographic Dermatologic Surgery',
  '769': 'Emergency Medicine, Anesthesiology Critical Care Medicine',
  '770': 'Emergency Medicine, Hospice and Palliative Medicine',
  '771': 'Emergency Medicine, Internal Medicine Critical Care Medicine',
  '772': 'Emergency Medicine, Neurocritical Care',
  '773': 'Emergency Medicine, Pain Medicine',
  '774': 'Emergency Medicine, Pediatric Emergency Medicine',
  '775': 'Emergency Medicine, Undersea and Hyperbaric Medicine',
  '776': 'Family Practice, Hospice and Palliative Medicine',
  '777': 'Family Practice, Pain Medicine',
  '778': 'Family Practice, Sleep Medicine',
  '779': 'Internal Medicine, Adult Congenital Heart Disease',
  '780': 'Internal Medicine, Advanced Heart Failure and Transplant Cardiology',
  '781': 'Internal Medicine, Clinical Cardiac Electrophysiology',
  '782': 'Internal Medicine, Endocrinology, Diabetes & Metabolism',
  '783': 'Internal Medicine, Hospice and Palliatice Medicine',
  '784': 'Internal Medicine, Magnetic Resonance Imaging (MRI)',
  '785': 'Internal Medicine, Sleep Medicine',
  '786': 'Internal Medicine, Transplant Hepatology',
  '787': 'Medical Genetics, Clinical Biochemical Genetics',
  '788': 'Medical Genetics, Clinical Genetics and Genomics (M.D.)',
  '789': 'Medical Genetics, Labratory Genetics and Genomics',
  '790': 'Medical Genetics, Medical Biochemical Genetics',
  '791': 'Medical Genetics, Molecular Genetic Pathology',
  '792': 'Neurological Surgery, Neurocritical Care',
  '793': 'Neuromusculoskeletal Medicine, Sports Medicine',
  '794': 'Nuclear Medicine, In Vivo & In Vitro Nuclear Medicine',
  '795': 'Nuclear Medicine, Nuclear Imaging & Therapy',
  '796': 'Obstetrics & Gynecology, Complex Family Planning',
  '797': 'Obstetrics & Gynecology, Critical Care Medicine',
  '798':
    'Obstetrics & Gynecology, Female Pelvic Medicine and Reconstructive Surgery',
  '799': 'Obstetrics & Gynecology, Gynecologic Oncology',
  '800': 'Obstetrics & Gynecology, Hospice and Palliative Medicine',
  '801': 'Obstetrics & Gynecology, Maternal & Fetal Medicine',
  '802': 'Obstetrics & Gynecology, Reproductive Endocrinology',
  '803': 'Orthopaedic Surgery, Adult Reconstructive Orthopaedic Surgery',
  '804': 'Orthopaedic Surgery, Foot and Ankle Orthopaedics',
  '805': 'Orthopaedic Surgery, Orthopaedic Surgery of the Spine',
  '806': 'Otolaryngology, Complex Pediatric Otolaryngology',
  '807': 'Otolaryngology, Neurotology',
  '808': 'Otolaryngology, Otolaryngology/ Facial Plastic Surgery',
  '809': 'Otolaryngology, Plastic Surgery within the Head & Neck',
  '810': 'Otolaryngology, Sleep Medicine',
  '811': 'Pathology, Anatomic Pathology & Clinical Pathology',
  '812': 'Pathology, Blood Banking & Transfusion Medicine',
  '813': 'Pathology, Clinical Informatics',
  '814': 'Pathology, Clinical Pathology',
  '815': 'Pathology, Clinical Pathology/Laboratory Medicine',
  '816': 'Pathology, Hematopathology',
  '817': 'Pathology, Molecular Genetic Pathology',
  '818': 'Pediatrics, Child Abuse Pediatrics',
  '819': 'Pediatrics, Clinical & Laboratory Immunology',
  '820': 'Pediatrics, Developmental – Behavioral Pediatrics',
  '821': 'Pediatrics, Hospice and Palliative Medicine',
  '822': 'Pediatrics, Pediatric Hospice Medicine',
  '823': 'Pediatrics, Pediatric Allergy & Immunology',
  '824': 'Pediatrics, Pediatric Transplant Hepatology',
  '825': 'Pediatrics, Sleep Medicine',
  '826': 'Physical Medicine & Rehabilitation, Brain Injury Medicine',
  '827': 'Physical Medicine & Rehabilitation, Hospice and Palliative Medicine',
  '828': 'Physical Medicine & Rehabilitation, Neuromuscular Medicine',
  '829': 'Physical Medicine & Rehabilitation, Pain Medicine',
  '830':
    'Physical Medicine & Rehabilitation, Pediatric Rehabilitation Medicine',
  '831': 'Physical Medicine & Rehabilitation, Spinal Cord Injury Medicine',
  '832': 'Physical Medicine & Rehabilitation, Sports Medicine',
  '833': 'Plastic Surgery, Facial Plastic Surgery',
  '834': 'Plastic Surgery, Plastic Surgery Within Head and Neck',
  '835': 'Preventative Medicine',
  '836': 'Preventative Medicine, Aerospace Medicine',
  '837': 'Preventative Medicine, Occupational Medicine',
  '838': 'Preventative Medicine, Public Health and General Preventive Medicine',
  '839': 'Preventative Medicine, Addiction Medicine',
  '840': 'Preventative Medicine, Clinical Informatics',
  '841': 'Preventative Medicine, Medical Toxicology',
  '842': 'Preventative Medicine, Undersea and Hyperbaric Medicine',
  '843': 'Psychiatry & Neurology, Addiction Psychiatry',
  '844': 'Psychiatry & Neurology, Brain Injury Medicine',
  '845': 'Psychiatry & Neurology, Child & Adolescent Psychiatry',
  '846': 'Psychiatry & Neurology, Clinical Neurophysiology',
  '847': 'Psychiatry & Neurology, Consultation - Liason Psychiatry ',
  '848': 'Psychiatry & Neurology, Epilepsy',
  '849': 'Psychiatry & Neurology, Forensic Psychiatry',
  '850': 'Psychiatry & Neurology, Hospice and Palliative Medicine',
  '851': 'Psychiatry & Neurology, Neurocritical Care',
  '852': 'Psychiatry & Neurology, Neurodevelopmental Disabilities',
  '853': 'Psychiatry & Neurology, Neuromuscular Medicine',
  '854':
    'Psychiatry & Neurology, Neurology with Special Qualifications in Child Neurology',
  '855': 'Psychiatry & Neurology, Sleep Medicine',
  '856': 'Radiology',
  '857': 'Radiology, Hospice and Palliative Medicine',
  '858': 'Radiology, Interventional Radiology and Diagnostic Radiology',
  '859': 'Radiology, Medical Physics (Diagnostic, Neuclear, Therapeutic)',
  '860': 'Radiology, Vascular & Interventional Radiology',
  '861': 'Thoracic and Cardiac Surgery',
  '862': 'Thoracic Surgery, Congenital Cardiac Surgery',
  '863': 'Surgery, Complex General Surgical Oncology',
  '864': 'Surgery, General Surgery',
  '865': 'Surgery, Hospice and Palliative Medicine',
  '866': 'Surgery, Plastic and Reconstructive Surgery',
  '867': 'Urology, Female Pelvic Medicine and Reconstructive Surgery',
  '868': 'Urology, Pedicatic Urology',
};

export const DDS_DMD = {
  'N/A': 'N/A',
  '2': 'Dentist',
  '13': 'Dentist, Dental Public Health',
  '14': 'Dentist, Endodontics',
  '15': 'Dentist, Ortho and Dentofacial Orthopedics',
  '16': 'Dentist, Oral and Maxillofacial Pathology',
  '17': 'Dentist, Pediatric Dentistry',
  '18': 'Dentist, Periodontics',
  '19': 'Dentist, Prosthodontics',
  '20': 'Dentist, Oral and Maxillofacial Surgery',
  '438': 'Dentist, General Practice',
  '439': 'Dentist, Oral and Maxillofacial Radiology',
};

export const DC = {
  'N/A': 'N/A',
  '1': 'Chiropractor',
  '5': 'Chiropractor, Internist',
  '6': 'Chiropractor, Neurology',
  '7': 'Chiropractor, Nutrition',
  '8': 'Chiropractor, Occupational Medicine',
  '9': 'Chiropractor, Orthopedic',
  '10': 'Chiropractor, Radiology',
  '11': 'Chiropractor, Sports Physician',
  '12': 'Chiropractor, Thermography',
};

export const DPM = {
  'N/A': 'N/A',
  '3': 'Podiatrist',
  '225': 'Podiatrist, General Practice',
  '226': 'Podiatrist, Public Medicine',
  '227': 'Podiatrist, Primary Podiatric Medicine',
  '228': 'Podiatrist, Radiology',
  '229': 'Podiatrist, Sports Medicine',
  '230': 'Podiatrist, Foot Surgery',
  '231': 'Podiatrist, Foot and Ankle Surgery',
};

export const MD_DP_DC = {
  'N/A': '*Not applicable',
  '106': 'American Board of Public Health Dentistry',
  '108': 'American Board of Orthodontics',
  '109': 'American Board of Oral & Maxillofacial Surgeons',
  '111': 'American Board of Periodontology',
  '112': 'American Board of Pediatric Dentistry',
  '113': 'American Board of Endodontics',
  '114': 'American Board of Oral & Maxillofacial Pathology',
  '115': 'American Board of Prosthodontics',
  '117': 'American Board of Oral & Maxillofacial Radiology',
  '118': 'American Osteopathic Board of Anesthesiology',
  '119': 'American Osteopathic Board of Dermatology',
  '120': 'American Osteopathic Board of Emergency Medicine',
  '121': 'American Osteopathic Board of Family Practice',
  '123': 'American Osteopathic Board of Internal Medicine',
  '124': 'American Osteopathic Board Neuro/Psychiatry',
  '125': 'American Osteo Board  Neuromuskuloskeletal Medicine',
  '126': 'American Osteopathic Board of Nuclear Medicine',
  '127': 'American Osteopathic Board Obstetrics  and Gynecology',
  '128': 'American Osteopathic Board of Ophthalmology and Otolaryngology',
  '129': 'American  Osteo Board of Orthopedic Surgery',
  '130': 'American Osteopathic Board of Pathology',
  '131': 'American Osteopathic Board of Pediatrics',
  '132': 'American Osteopathic Board of Preventive  Medicine',
  '133': 'American Osteopathic Board of Proctology',
  '134': 'American Osteopathic Board of Radiology',
  '135': 'American Osteopathic Board of Rehabilitation Medicine',
  '136': 'American Osteopathic Board of Surgery',
  '137':
    'American Board of Podiatric Orthopedics and Primary Podiatric Medicine',
  '138': 'American Board of Podiatric Surgery',
  '139': 'American Council of Certified Podiatric Surgeons and Physicians',
  '140': 'American Board of Medical Specialists Podiatry',
  '142': 'Boards other than ABMS/AOA',
  '044': 'American Board of Allergy & Immunology',
  '045': 'American Board of Anesthesiology',
  '046': 'American Board of Colon & Rectal Surgery',
  '047': 'American Board of Dermatology',
  '048': 'American Board of Emergency Medicine',
  '049': 'American Board of Family Medicine',
  '050': 'American Board of Internal Medicine',
  '051': 'American Board of Medical Genetics',
  '052': 'American Board of Neurological Surgery',
  '053': 'American Board of Nuclear Medicine',
  '054': 'American Board of Obstetrics & Gynecology',
  '055': 'American Board of Ophthalmology',
  '056': 'American Board of Orthopedic Surgery',
  '057': 'American Board of Otolaryngology',
  '058': 'American Board of Pathology',
  '059': 'American Board of Pediatrics',
  '060': 'American Board of Physical Medicine & Rehabilitation',
  '061': 'American Board of Plastic Surgery',
  '062': 'American Board of Preventive Medicine',
  '063': 'American Board of Psychiatry & Neurology',
  '064': 'American Board of Radiology',
  '065': 'American Board of Surgery',
  '066': 'American Board of Thoracic Surgery',
  '067': 'American Board of Urology',
  American: 'American Society of Podiatric Executives',
  Society: 'Society for Vascular Surgery',
  Academy: 'Academy of Ambulatory Foot and Ankle Surgery',
  Association: 'Association of Extremity Nerve Surgeons',
  Canadian: 'Canadian Podiatric Medical Association',
  Federation: 'Federation of Podiatric Medical Boards',
  National: 'National Podiatric Medical Association',
  Student: 'Student National Podiatric Medical Association',
};

export const MD_DO_BOARD = {
  'N/A': '*Not applicable',
  '067': 'American Association of Clinical Endocrinologists ',
  '068': 'American Association of Colleges of Osteopathic Medicine ',
  '069': 'American Association of Electrodiagnostic Medicine ',
  '070': 'American Association of Neurological Surgeons ',
  '071': 'American Board of Abdominal Surgery ',
  '072': 'American Board of Addiction Medicine ',
  '073': 'American Board of Allergy & Immunology ',
  '074': 'American Board of Anesthesiology ',
  '075': 'American Board of Audiology ',
  '076': 'American Board of Clinical Lipidology ',
  '077': 'American Board of Clinical Neuropsychology ',
  '078': 'American Board of Colon & Rectal Surgery ',
  '079': 'American Board of Dermatology ',
  '080': 'American Board of Emergency Medicine ',
  '081': 'American Board of Endodontics ',
  '082': 'American Board of Facial Plastic and Reconstructive Surgery ',
  '083': 'American Board of Family Medicine ',
  '084': 'American Board of Foot and Ankle Surgery ',
  '085': 'American Board of Internal Medicine ',
  '086': 'American Board of Interventional Pain Physicians ',
  '087': 'American Board of Lower Extremity Surgery ',
  '088': 'American Board of Medical Genetics ',
  '089': 'American Board of Medical Specialists in Podiatry ',
  '090': 'American Board of Multiple Specialties in Podiatry ',
  '091': 'American Board of Neurological Surgery ',
  '092': 'American Board of Nuclear Medicine ',
  '093': 'American Board of Obestetrics & Gynecology ',
  '094': 'American Board of Ophthalmology ',
  '095': 'American Board of Optometry ',
  '096': 'American Board of Oral and Maxillofacial Pathology ',
  '097': 'American Board of Oral and Maxillofacial Radiology ',
  '098': 'American Board of Oral and Maxillofacial Surgery ',
  '099': 'American Board of Orofacial Pain ',
  '100': 'American Board of Orthodontics ',
  '101': 'American Board of Orthopaedic Surgery ',
  '102': 'American Board of Otolaryngology ',
  '103': 'American Board of Pathology ',
  '104': 'American Board of Pediatric Dentistry ',
  '105': 'American Board of Pediatric Neurological Surgery ',
  '106': 'American Board of Pediatrics ',
  '107': 'American Board of Peridontology ',
  '108': 'American Board of Physical Medicine & Rehabilitation ',
  '109': 'American Board of Physical Therapy Specialties ',
  '110': 'American Board of Physician Specialties ',
  '111': 'American Board of Plastic Surgery ',
  '112': 'American Board of Preventive Medicine ',
  '113': 'American Board of Professional Psychology ',
  '114': 'American Board of Prosthodontics ',
  '115': 'American Board of Psychiatry and Neurology ',
  '116': 'American Board of Public Health Dentistry ',
  '117': 'American Board of Radiology ',
  '118': 'American Board of Sleep Medicine ',
  '119': 'American Board of Surgery ',
  '120': 'American Board of Throacic Surgery ',
  '121': 'American Board of Urgent Care Medicine ',
  '122': 'American Board of Urology ',
  '123': 'American Board of Vascular Medicine ',
  '124': 'American Board of Venous & Lymphatic Medicine ',
  '125': 'American Board of Wound Healing ',
  '126': 'American Board of Wound Management ',
  '127': 'American Cancer Society ',
  '128': 'American College of Cardiology ',
  '129': 'American College of Chest Physicians ',
  '130': 'American College of Emergency Physicians ',
  '131': 'American College of Obstetricians and Gynecologists ',
  '132': 'American College of Physicians ',
  '133': 'American College of Preventive Medicine ',
  '134': 'American College of Radiology ',
  '135': 'American College of Rheumatology ',
  '136': 'American College of Surgeons ',
  '137': 'American Heart Association ',
  '138': 'American Institute of Ultrasound in Medicine ',
  '139': 'American Medical Association [AMA] ',
  '140': 'American Midwifery Certification Board ',
  '141': 'American Neurological Association ',
  '142': 'American Osteopathic Association ',
  '143': 'American Osteopathic Board of Anesthesiology ',
  '144': 'American Osteopathic Board of Dermatology ',
  '145': 'American Osteopathic Board of Emergency Medicine ',
  '146': 'American Osteopathic Board of Family Practice ',
  '147': 'American Osteopathic Board of Internal Medicine ',
  '148': 'American Osteopathic Board of Neurology and Psychiatry ',
  '149': 'American Osteopathic Board of Neuromusculoskeletal Medicine ',
  '150': 'American Osteopathic Board of Nuclear Medicine ',
  '151': 'American Osteopathic Board of Obstetrics and Gynecology ',
  '152': 'American Osteopathic Board of Ophthalmology and Otolaryngology ',
  '153': 'American Osteopathic Board of Orthopedic Surgery ',
  '154': 'American Osteopathic Board of Pathology ',
  '155': 'American Osteopathic Board of Pediatrics ',
  '156': 'American Osteopathic Board of Physical Medicine and Rehabilitation  ',
  '157': 'American Osteopathic Board of Preventive Medicine ',
  '158': 'American Osteopathic Board of Proctology ',
  '159': 'American Osteopathic Board of Radiology ',
  '160': 'American Osteopathic Board of Surgery ',
  '161': 'American Psychiatric Association ',
  '162': 'American Registry for Diagnostic Medical Sonography ',
  '163': 'American Roentgen Ray Society ',
  '164': 'American Society for Bariatric Surgery ',
  '165': 'American Society for Dermatologic Surgery ',
  '166': 'American Society for Gastrointestinal Endoscopy ',
  '167': 'American Society for Therapeutic Radiology and Oncology ',
  '168': 'American Society of Addiction Medicine ',
  '169': 'American Society of Anesthesiologists ',
  '170': 'American Society of Cataract and Refractive Surgery ',
  '171': 'American Society of Clinical Oncology ',
  '172': 'American Society of Internal Medicine ',
  '173': 'American Society of Neuroimaging ',
  '174': 'American Society of Ophthalmic Plastic and Reconstructive Surgery ',
  '175': 'American Society of Plastic and Reconstructive Surgeons ',
  '176': 'American Thoracic Society ',
  '177': 'American Urological Association ',
  '178': 'AOA (American Osteopathic Association) ',
  '179': 'Association of Extremity Nerve Surgeons ',
  '180': 'Centers for Disease Control ',
  '181': 'Certification Board of CV Computed Tomography ',
  '182': 'Certification Board of Nuclear Cardiology ',
  '183': 'College of American Pathologists ',
  '184': 'Council for Certification in CV Imaging ',
  '185': 'Council of Medical Specialty Societies ',
  '186': 'Hand Therapy Certification Commission ',
  '187': 'National Alliance of Wound Care and Ostomy ',
  '188': 'National Board of Echocardiography ',
  '189': 'National Board of Medical Examiners ',
  '190': 'National Board of Physicians and Surgeons ',
  '191': 'National Cancer Institute ',
  '192': 'National Commission on Certification of Physician Assistants ',
  '193': 'National Council on the Aging ',
  '194': 'National Eye Institute ',
  '195': 'National Heart',
  '196': 'National Hemophilia Foundation ',
  '197': 'National Institute of Allergy and Infectious Diseases ',
  '198':
    'National Institute of Arthritis and Musculoskeletal and Skin Diseases ',
  '199': 'National Institute of Child Health and Human Development ',
  '200': 'National Institute of Diabetes & Digestive & Kidney Diseases ',
  '201': 'National Institute on Aging ',
  '202': 'National Institutes of Health ',
  '203': 'Royal College of Physicians & Surgeons of Canada ',
  '204': 'Society for Investigative Dermatology ',
  '205': 'Society for Vascular Surgery ',
  '206': 'Society of American Gastrointestinal Endoscopic Surgeons ',
  '207': 'Society of Cardiovascular & Interventional Radiology ',
  '208': 'Society of Critical Care Medicine ',
  '209': 'Society of Nuclear Medicine ',
  '210': 'Society of Thoracic Surgeons ',
  '211': 'United Council for Neurologic Subspecialties ',
  '212': 'ABLES (American Board for Lower Extremity Surgery) ',
  '215': 'Alliance for Physician Certification & Advancement ',
  '216': 'American Academy of Allergy Asthma & Immunology ',
  '217': 'American Academy of Child & Adolescent Psychiatry ',
  '218': 'American Academy of Dermatology ',
  '219': 'American Academy of Experts in Traumatic Stress ',
  '220': 'American Academy of Family Physicians ',
  '221': 'American Academy of HIV Medicine ',
  '222': 'American Academy of Neurology ',
  '223': 'American Academy of Ophthalmology ',
  '224': 'American Academy of Orthopaedic Surgeons ',
  '225': 'American Academy of Pain Medicine ',
  '226': 'American Academy of Pediatrics ',
  '227': 'American Academy of Physical Medicine & Rehabilitation ',
};

export const DDS_DMD_BOARD = {
  'N/A': '*Not applicable',
  '400': 'American Association of Public Health Dentistry',
  '401': 'American Association of Endodontists',
  '402': 'American Academy of Oral & Maxillofacial Radiology',
  '403': 'American Association of Oral and Maxillofacial Surgeons',
  '404': 'American Association of Orthodontists',
  '405': 'American Academy of Pediatric Dentistry',
  '406': 'American Academy of Periodontology',
  '407': 'American College of Prosthodontists',
};

export const DPM_BOARD = {
  '300': 'ABPM (American Board of Podiatric Medicine)',
  '301': 'Academy of Ambulatory Foot and Ankle Surgery',
  '302': 'American Academy of Podiatric Practice Management',
  '303': 'American Academy of Podiatric Sports Medicine',
  '304': 'American Association for Women Podiatrists',
  '305': 'American Association of Colleges of Podiatric Medicine',
  '306': 'American Board of Podiatric Medicine',
  '307':
    'American Board of Podiatric Orthopedics and Primary Podiatric Medicine',
  '308': 'American Board of Podiatric Surgery',
  '309': 'American College of Foot & Ankle Orthopedics & Medicine',
  '310': 'American College of Foot and Ankle Surgeons',
  '311': 'American College of Foot and Medical Pediatrics',
  '312': 'American Podiatric Medical Association',
  '313': "American Podiatric Medical Students' Association",
  '314': 'American Public Health Association (Podiatric Section)',
  '315': 'American Society of Foot and Ankle Dermatology',
  '316': 'American Society of Podiatric Executives',
  '317': 'American Society of Podiatric Medical Assistants',
  '318': 'American Society of Podiatric Medicine',
  '319': 'American Society of Podiatric Surgeons',
  '320': 'Canadian Podiatric Medical Association',
  '321': 'Federation of Podiatric Medical Boards',
  '322': 'National Podiatric Medical Association',
};

export const DC_BOARD = {
  '350': 'Academy of Chiropractic Orthopedists',
  '351': 'American Chiropractic Board of Occupational Health',
  '352': 'American Chiropractic Board of Sports Physicians',
  '353': 'American Chiropractic Rehabilitation Board',
  '354': 'Chiropractic Board of Clinical Nutrition',
};
export const Allied_Health_BOARD = {
  'N/A': 'N/A',
  '1': 'AACN-American Association of Critical Care Nurses',
  '2': 'AAMFT-American Association for Marriage & Family Therapy',
  '3': 'AANP-American Academy of Nurse Practitioners',
  '4': 'ABA-American Board of Audiology',
  '5': 'ABESW-American Board of Examiners in Social Work',
  '6': 'ABNP-American Board of Nursing Specialists',
  '7': 'ABPP-American Board of Professional Psychology',
  '8': 'ABPT-American Board of Physical Therapy',
  '9': 'ACSW-Academy of Certified Social Workers',
  '10': 'AMCB-American Midwfery Certification Board',
  '11': 'AMECSW-American Board of Examiners in Clinical Social Work',
  '12': 'American Association of Colleges of Nursing CNL',
  '13': 'American Academy of Child & Adolescent Psychiatry',
  '14': 'American Academy of Physical Medicine & Rehabilitation',
  '15': 'American Association of Diabetes Educators',
  '16': 'American Association of Medical Review Officers',
  '17': 'American Dance Therapy Association',
  '18': 'American Institute of Ultrasound in Medicine',
  '19': 'American Midwifery Certification Board',
  '20': 'American Psychiatric Association',
  '21': 'American Psychological Association',
  '22': 'American Society of Addiction Medicine',
  '23': 'ANA (American Counseling Association)',
  '24': 'ANCC (American Nurse Credentialing Center)',
  '25': 'Art Therapy Credentials Board',
  '26': 'ASHA (American Speech Language Hearing Association)',
  '27': 'Association for Play Therapy',
  '28': 'Association of Critical Care Nurses',
  '29': 'BACB (Behavior Analyst Certification Board)',
  '30': 'Biofeedback Certification International Alliance',
  '31': 'Board of Certification for the Athletic Trainer',
  '32': 'Board of Nephrology Examiners Nursing & Technology',
  '33': 'Certification Board for Music Therapists',
  '34': 'Clinical Nutrition Certification Board',
  '35': 'Commission for Case Manager Certification',
  '36': 'Commission on Dietetic Registration',
  '37': 'Community Health Accreditation Partner',
  '38': 'HIV/AIDS Nursing Certification Board',
  '39': 'ICA Council on Chiropractic Pediatrics',
  '40': 'International Alcohol & Drug Counselors',
  '41': 'International Board of Lactation Consultant Examiners',
  '42': 'International Society for Clinical Densitometry',
  '43': 'Kinesiotaping Certification',
  '44': 'NASW (National Association of Social Workers)',
  '45': 'National Asthma Educator Certification Board',
  '46': 'National Board for Certification of Orthopaedic PAs',
  '47': 'National Board of Certification & Recertification for Nurse Anesthetists',
  '48': 'National Center for Crisis Management',
  '49': 'National Certification Board for Diabetes Educators',
  '50': 'National Certification Commission for Acupuncture and Oriental Medicine',
  '51': 'National Certification Commission for Addiction Professionals',
  '52': 'National Certification Corporation',
  '53': 'National Council on Problem Gambling',
  '54': 'National Institute on Deafness and Other Communication Disorders',
  '55': 'National Register of Health Service Psychologists',
  '56': 'National Strength and Conditioning Association',
  '57': 'NBCC (National Board for Certified Counselors)',
  '58': 'NBCOT (National Board for Certification in Occupational Therapy )',
  '59': 'NBEO (National Board of Examiners for Optometry)',
  '60': 'NCCPA (National Commission on Certification of Physician Assistants)',
  '61': 'Oncology Nursing Certification Corporation',
  '62': 'PNCB (Pediatric Nursing Certification Board)',
  '63': 'Radiological Society of North America',
  '64': 'The National Commission on Certification of Physician Assistants',
  '65': 'Trauma-Focused Cognitive Behavioral Therapy-National Therapist Certification Program',
  '66': 'Wound, Ostomy and Continence Nursing Certification Board',
  '67': 'National Board of Surgical Technology and Surgical Assisting',
};

export const ALL_BOARDS = {
  ...MD_DO_BOARD,
  ...DDS_DMD_BOARD,
  ...DPM_BOARD,
  ...DC_BOARD,
  ...Allied_Health_BOARD,
};

// Documentation
export const DocumentationTypes = [
  {
    value: 'ACLS',
    label: 'ACLS',
    quantity: '0',
  },
  {
    value: 'ATLS',
    label: 'ATLS',
    quantity: '0',
  },
  {
    value: 'BLS',
    label: 'CPR/BCLS/BLS',
    quantity: '0',
  },
  {
    value: 'State Controlled Substance Certificate',
    label: 'State Controlled Dangerous Substance Certificate',
    quantity: '0',
  },
  {
    value: 'Medical School Degree',
    label: 'Medical Degree and Training',
    quantity: '0',
  },
  {
    value: 'Permanent Residency',
    label: 'Permanent Residency/Green Card/Visa',
    quantity: '0',
  },
  {
    value: 'certificates of training',
    label: 'Medical License',
    quantity: '0',
  },
  {
    value: 'DEA certificate',
    label: 'DEA Certificate',
    quantity: '0',
  },
  {
    value: 'ECFMG certificate',
    label: 'ECFMG Certificate',
    quantity: '0',
  },
  {
    value: 'Health Attestation',
    label: 'Health Attestation/TB Testing/ Immunization Records',
    quantity: '0',
  },
  {
    value: 'infection control certificate',
    label: 'Infection Control Certificate',
    quantity: '0',
  },
  {
    value: 'NALS',
    label: 'NALS',
    quantity: '0',
  },
  {
    value: 'NRP',
    label: 'NRP',
    quantity: '0',
  },
  {
    value: 'PALS',
    label: 'PALS',
    quantity: '0',
  },
  {
    value: 'Driver License',
    label: "Driver's License",
    quantity: '0',
  },
  {
    value: 'Specialty Board Certifications',
    label: 'Specialty Board Certifications',
    quantity: '0',
  },
  {
    value: 'Proof of Insurance',
    label: 'Proof of Insurance',
    quantity: '0',
  },
  {
    value: 'ALSO',
    label: 'ALSO',
    quantity: '0',
  },
  {
    value: 'ABLS',
    label: 'ABLS',
    quantity: '0',
  },
  {
    value: 'CME Credits',
    label: 'CME Credits',
    quantity: '0',
  },
  {
    value: 'Corporate LLC DBA',
    label: 'Corporate LLC/DBA Documents',
    quantity: '0',
  },
  {
    value: 'Banking Information',
    label: 'Banking Information',
    quantity: '0',
  },
  {
    value: 'Misc',
    label: 'Misc Documents',
    quantity: '0',
  },
  {
    value: 'NPI Confirmation',
    label: 'NPI Confirmation',
    quantity: '0',
  },
  {
    value: 'CLIA Certificate',
    label: 'CLIA Certificate',
    quantity: '0',
  },
  {
    value: 'cv-resume',
    label: 'CV/Resume',
    quantity: '0',
  },
  {
    value: 'scheduling-agreement',
    label: 'Scheduling Agreement',
    quantity: '0',
  },
  {
    value: 'delineation-privileges',
    label: 'Delineation of Privileges',
    quantity: '0',
  },
  {
    value: 'opioid-training',
    label: 'Opioid Training',
    quantity: '0',
  },
];
// Customized Verifications Detail
export const Customized_Verifications_Types = [
  { title: 'MD', id: '1' },
  { title: 'DO', id: '2' },
  { title: 'DPM', id: '3' },
  { title: 'DDS/DMD', id: '4' },
  { title: 'PA', id: '5' },
  { title: 'DC', id: '6' },
  { title: 'NP', id: '7' },
  { title: 'CRNA', id: '8' },
  { title: 'RN', id: '9' },
];

export const Customized_Verifications_Others_Answers = [
  // Background Check
  {
    Verification: 'MD',
    Question: 'BackgroundCheck',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'BackgroundCheck',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'BackgroundCheck',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'BackgroundCheck',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'BackgroundCheck',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  {
    Verification: 'Other',
    Question: 'BackgroundCheck',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  // BoardCertification
  {
    Verification: 'MD',
    Question: 'BoardCertification',
    Type: 'dropdown',
    Answers: ['AMA', 'Certifacts/ABMS', 'Certifying Board', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'BoardCertification',
    Type: 'dropdown',
    Answers: ['AOA', 'Certifacts/ABMS', 'Certifying Board', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'BoardCertification',
    Type: 'dropdown',
    Answers: ['ABPM', 'ABFAS', 'Certifying Board', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['Certifying Board'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'BoardCertification',
    Type: 'dropdown',
    Answers: ['Certifying Board', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'Other',
    Question: 'BoardCertification',
    Type: 'dropdown',
    Answers: ['Certifying Board', 'OTHER'],
    Disable: false,
  },
  // CAQH
  {
    Verification: 'MD',
    Question: 'CAQH',
    Type: 'string',
    Answers: ['CAQH'],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'CAQH',
    Type: 'string',
    Answers: ['CAQH'],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'CAQH',
    Type: 'string',
    Answers: ['CAQH'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'CAQH',
    Type: 'string',
    Answers: ['CAQH'],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'CAQH',
    Type: 'string',
    Answers: ['CAQH'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'CAQH',
    Type: 'string',
    Answers: ['CAQH'],
    Disable: true,
  },
  // CDS
  {
    Verification: 'MD',
    Question: 'CDS',
    Type: 'dropdown',
    Answers: ['STATE SITE', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'CDS',
    Type: 'dropdown',
    Answers: ['STATE SITE', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'CDS',
    Type: 'dropdown',
    Answers: ['STATE SITE', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'CDS',
    Type: 'dropdown',
    Answers: ['STATE SITE', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'CDS',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'CDS',
    Type: 'dropdown',
    Answers: ['STATE SITE', 'OTHER'],
    Disable: false,
  },
  //COI
  {
    Verification: 'MD',
    Question: 'COI',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'COI',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'COI',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'COI',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'COI',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'COI',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  // DEA
  {
    Verification: 'MD',
    Question: 'DEA',
    Type: 'dropdown',
    Answers: ['DEA', 'AMA', 'FSMB', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'DEA',
    Type: 'dropdown',
    Answers: ['DEA', 'AOA', 'FSMB', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'DEA',
    Type: 'dropdown',
    Answers: ['DEA', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'DEA',
    Type: 'dropdown',
    Answers: ['DEA', 'AMA', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'DEA',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'DEA',
    Type: 'dropdown',
    Answers: ['DEA', 'OTHER'],
    Disable: false,
  },
  //DeathMaster
  {
    Verification: 'MD',
    Question: 'DeathMaster',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'DeathMaster',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'DeathMaster',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'DeathMaster',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'DeathMaster',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'DeathMaster',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  // Drug Screening
  {
    Verification: 'MD',
    Question: 'DrugScreening',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'DrugScreening',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'DrugScreening',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'DrugScreening',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'DrugScreening',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  {
    Verification: 'Other',
    Question: 'DrugScreening',
    Type: 'dropdown',
    Answers: ['CastleBranch', 'Other'],
    Disable: false,
  },
  //Education
  {
    Verification: 'MD',
    Question: 'Education',
    Type: 'dropdown',
    Answers: ['AMA', 'NSCH', 'SCHOOL', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'Education',
    Type: 'dropdown',
    Answers: ['AOA', 'NSCH', 'SCHOOL', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'Education',
    Type: 'dropdown',
    Answers: ['ABPM', 'ABAFS', 'NSCH', 'SCHOOL', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'Education',
    Type: 'dropdown',
    Answers: ['NSCH', 'AMA', 'AOA', 'SCHOOL', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'Education',
    Type: 'dropdown',
    Answers: ['NSCH', 'AMA', 'AOA', 'SCHOOL', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'Other',
    Question: 'Education',
    Type: 'dropdown',
    Answers: ['NSCH', 'PARCHMENT', 'SCHOOL', 'OTHER'],
    Disable: false,
  },
  // FSMBQuery
  {
    Verification: 'MD',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  //GovIssued
  {
    Verification: 'MD',
    Question: 'GovIssued',
    Type: 'dropdown',
    Answers: ['DOCUMENT', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'GovIssued',
    Type: 'dropdown',
    Answers: ['DOCUMENT', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'GovIssued',
    Type: 'dropdown',
    Answers: ['DOCUMENT', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'GovIssued',
    Type: 'dropdown',
    Answers: ['DOCUMENT', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'GovIssued',
    Type: 'dropdown',
    Answers: ['DOCUMENT', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'Other',
    Question: 'GovIssued',
    Type: 'dropdown',
    Answers: ['DOCUMENT', 'OTHER'],
    Disable: false,
  },
  //HospitalAffiliate
  {
    Verification: 'MD',
    Question: 'HospitalAffiliate',
    Type: 'dropdown',
    Answers: ['FACILITY', 'NAMSS PASS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'HospitalAffiliate',
    Type: 'dropdown',
    Answers: ['FACILITY', 'NAMSS PASS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'HospitalAffiliate',
    Type: 'dropdown',
    Answers: ['FACILITY', 'NAMSS PASS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'HospitalAffiliate',
    Type: 'dropdown',
    Answers: ['FACILITY', 'NAMSS PASS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'HospitalAffiliate',
    Type: 'string',
    Answers: ['FACILITY'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'HospitalAffiliate',
    Type: 'dropdown',
    Answers: ['FACILITY', 'NAMSS PASS', 'OTHER'],
    Disable: false,
  },
  // Life Saving Certifications
  {
    Verification: 'MD',
    Question: 'LifeSavingCertifications',
    Type: 'string',
    Answers: ['Certifying Org'],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'LifeSavingCertifications',
    Type: 'string',
    Answers: ['Certifying Org'],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'LifeSavingCertifications',
    Type: 'string',
    Answers: ['Certifying Org'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'LifeSavingCertifications',
    Type: 'string',
    Answers: ['Certifying Org'],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'LifeSavingCertifications',
    Type: 'string',
    Answers: ['Certifying Org'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'LifeSavingCertifications',
    Type: 'string',
    Answers: ['Certifying Org'],
    Disable: true,
  },
  // MedicareOptOutList
  {
    Verification: 'MD',
    Question: 'MedicareOptOutList',
    Type: 'string',
    Answers: ['OPT OUT LIST'],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'MedicareOptOutList',
    Type: 'string',
    Answers: ['OPT OUT LIST'],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'MedicareOptOutList',
    Type: 'string',
    Answers: ['OPT OUT LIST'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'MedicareOptOutList',
    Type: 'string',
    Answers: ['OPT OUT LIST'],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'MedicareOptOutList',
    Type: 'string',
    Answers: ['OPT OUT LIST'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'MedicareOptOutList',
    Type: 'string',
    Answers: ['OPT OUT LIST'],
    Disable: true,
  },
  //MilitaryService
  {
    Verification: 'MD',
    Question: 'MilitaryService',
    Type: 'dropdown',
    Answers: ['AMA', 'DMDC', 'DD214', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'MilitaryService',
    Type: 'dropdown',
    Answers: ['AOA', 'DMDC', 'DD214', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'MilitaryService',
    Type: 'dropdown',
    Answers: ['DMDC', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'MilitaryService',
    Type: 'dropdown',
    Answers: ['DMDC', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'MilitaryService',
    Type: 'dropdown',
    Answers: ['DMDC', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'Other',
    Question: 'MilitaryService',
    Type: 'dropdown',
    Answers: ['DMDC', 'OTHER'],
    Disable: true,
  },
  // NPDB
  {
    Verification: 'MD',
    Question: 'NPDB',
    Type: 'dropdown',
    Answers: ['Continuous Query', 'Individual Report', 'Self Report'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'NPDB',
    Type: 'dropdown',
    Answers: ['Continuous Query', 'Individual Report', 'Self Report'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'NPDB',
    Type: 'dropdown',
    Answers: ['Continuous Query', 'Individual Report', 'Self Report'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'NPDB',
    Type: 'dropdown',
    Answers: ['Continuous Query', 'Individual Report', 'Self Report'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'NPDB',
    Type: 'dropdown',
    Answers: ['Continuous Query', 'Individual Report', 'Self Report'],
    Disable: false,
  },
  {
    Verification: 'Other',
    Question: 'NPDB',
    Type: 'dropdown',
    Answers: ['Continuous Query', 'Individual Report', 'Self Report'],
    Disable: false,
  },
  // NPI
  {
    Verification: 'MD',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  //NSCH
  {
    Verification: 'MD',
    Question: 'NSCH',
    Type: 'string',
    Answers: ['NSCH'],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'NSCH',
    Type: 'string',
    Answers: ['NSCH'],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'NSCH',
    Type: 'string',
    Answers: ['NSCH'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'NSCH',
    Type: 'string',
    Answers: ['NSCH'],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'NSCH',
    Type: 'string',
    Answers: ['NSCH'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'NSCH',
    Type: 'string',
    Answers: ['NSCH'],
    Disable: true,
  },
  //NSOW
  {
    Verification: 'MD',
    Question: 'NSOW',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'NSOW',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'NSOW',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'NSOW',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'NSOW',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'NSOW',
    Type: 'string',
    Answers: [],
    Disable: true,
  },
  // OIGQuery
  {
    Verification: 'MD',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB', 'FSMB'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB', 'FSMB'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB', 'FSMB'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB', 'FSMB'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'Other',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  // Opioid Training
  {
    Verification: 'MD',
    Question: 'OPIOIDTraining',
    Type: 'string',
    Answers: ['Training Certificate'],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'OPIOIDTraining',
    Type: 'string',
    Answers: ['Training Certificate'],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'OPIOIDTraining',
    Type: 'string',
    Answers: ['Training Certificate'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'OPIOIDTraining',
    Type: 'string',
    Answers: ['Training Certificate'],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'OPIOIDTraining',
    Type: 'string',
    Answers: ['Training Certificate'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'OPIOIDTraining',
    Type: 'string',
    Answers: ['Training Certificate'],
    Disable: true,
  },
  // ProfessionalLicense
  {
    Verification: 'MD',
    Question: 'ProfessionalLicense',
    Type: 'dropdown',
    Answers: ['STATE BOARD', 'AMA', 'FSMB', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'ProfessionalLicense',
    Type: 'dropdown',
    Answers: ['STATE BOARD', 'AOA', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'ProfessionalLicense',
    Type: 'dropdown',
    Answers: ['STATE BOARD', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'ProfessionalLicense',
    Type: 'dropdown',
    Answers: ['STATE BOARD', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'ProfessionalLicense',
    Type: 'dropdown',
    Answers: ['STATE BOARD', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'Other',
    Question: 'ProfessionalLicense',
    Type: 'dropdown',
    Answers: ['STATE BOARD', 'OTHER'],
    Disable: false,
  },
  //References
  {
    Verification: 'MD',
    Question: 'References',
    Type: 'string',
    Answers: ['References'],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'References',
    Type: 'string',
    Answers: ['References'],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'References',
    Type: 'string',
    Answers: ['References'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'References',
    Type: 'string',
    Answers: ['References'],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'References',
    Type: 'string',
    Answers: ['References'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'References',
    Type: 'string',
    Answers: ['References'],
    Disable: true,
  },
  //SAM
  {
    Verification: 'MD',
    Question: 'SAM',
    Type: 'string',
    Answers: ['SAM'],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'SAM',
    Type: 'string',
    Answers: ['SAM'],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'SAM',
    Type: 'string',
    Answers: ['SAM'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'SAM',
    Type: 'string',
    Answers: ['SAM'],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'SAM',
    Type: 'string',
    Answers: ['SAM'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'SAM',
    Type: 'string',
    Answers: ['SAM'],
    Disable: true,
  },
  //Training
  {
    Verification: 'MD',
    Question: 'Training',
    Type: 'dropdown',
    Answers: ['AMA', 'CERTIFICATE', 'NSCH', 'OTHER', 'TRAINING FACILITY'],
    Disable: false,
  },
  {
    Verification: 'DO',
    Question: 'Training',
    Type: 'dropdown',
    Answers: ['AOA', 'CERTIFICATE', 'NSCH', 'OTHER', 'TRAINING FACILITY'],
    Disable: false,
  },
  {
    Verification: 'DPM',
    Question: 'Training',
    Type: 'dropdown',
    Answers: ['NSCH', 'CERTIFICATE', 'OTHER', 'SCHOOL', 'TRAINING FACILITY'],
    Disable: false,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'Training',
    Type: 'dropdown',
    Answers: ['NSCH', 'CERTIFICATE', 'OTHER', 'SCHOOL', 'TRAINING FACILITY'],
    Disable: false,
  },
  {
    Verification: 'DC',
    Question: 'Training',
    Type: 'dropdown',
    Answers: ['NSCH', 'CERTIFICATE', 'OTHER', 'SCHOOL', 'TRAINING FACILITY'],
    Disable: false,
  },
  {
    Verification: 'Other',
    Question: 'Training',
    Type: 'dropdown',
    Answers: ['NSCH', 'CERTIFICATE', 'OTHER', 'SCHOOL', 'TRAINING FACILITY'],
    Disable: false,
  },
  //Tricare
  {
    Verification: 'MD',
    Question: 'Tricare',
    Type: 'string',
    Answers: ['Tricare'],
    Disable: true,
  },
  {
    Verification: 'DO',
    Question: 'Tricare',
    Type: 'string',
    Answers: ['Tricare'],
    Disable: true,
  },
  {
    Verification: 'DPM',
    Question: 'Tricare',
    Type: 'string',
    Answers: ['Tricare'],
    Disable: true,
  },
  {
    Verification: 'DDS/DMD',
    Question: 'Tricare',
    Type: 'string',
    Answers: ['Tricare'],
    Disable: true,
  },
  {
    Verification: 'DC',
    Question: 'Tricare',
    Type: 'string',
    Answers: ['Tricare'],
    Disable: true,
  },
  {
    Verification: 'Other',
    Question: 'Tricare',
    Type: 'string',
    Answers: ['Tricare'],
    Disable: true,
  },
];

// Allied health Detail
export const Allied_Health_Types = [
  { title: 'Psychiatrists', id: '10' },
  { title: 'Addiction Medicine', id: '11' },
  { title: 'Psychologist, PhD or PsyD', id: '12' },
  { title: 'LMFT', id: '13' },
  { title: 'LPC', id: '14' },
  { title: 'APSW', id: '15' },
  { title: 'ISW', id: '16' },
  { title: 'LCSW', id: '17' },
  { title: 'SAC', id: '18' },
  { title: 'COUNSELORS/MA, MS, MSE, MSW', id: '19' },
  { title: 'LBA', id: '20' },
  { title: 'SNM', id: '21' },
  { title: 'AuD', id: '22' },
  { title: 'PT', id: '23' },
  { title: 'OT', id: '24' },
  { title: 'SLP', id: '25' },
  { title: 'RD', id: '26' },
  { title: 'SURGICAL TECH', id: '27' },
  { title: 'CNM', id: '28' },
  { title: 'BCBA', id: '29' },
  { title: 'RBT', id: '30' },
];
export const Allied_Health_Others_Answers = [
  // Psychiatrists Verfication
  {
    Verification: 'Psychiatrists',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'DEA',
    Type: 'string',
    Answers: ['DEA'],
    Disable: true,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: ['FACILITY'],
    Disable: true,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'Psychiatrists',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // Addiction Medicine Verfication
  {
    Verification: 'Addiction Medicine',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'Addiction Medicine',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // Psychologist, PhD or PsyD Verfication
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'Psychologist, PhD or PsyD',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // LMFT Verfication
  {
    Verification: 'LMFT',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'LMFT',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'LMFT',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'LMFT',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'LMFT',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'LMFT',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'LMFT',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'LMFT',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'LMFT',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'LMFT',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'LMFT',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'LMFT',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'LMFT',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'LMFT',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'LMFT',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'LMFT',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // LPC Verfication
  {
    Verification: 'LPC',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'LPC',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'LPC',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'LPC',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'LPC',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'LPC',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'LPC',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'LPC',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'LPC',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'LPC',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'LPC',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'LPC',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'LPC',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'LPC',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'LPC',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'LPC',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // APSW Verfication
  {
    Verification: 'APSW',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'APSW',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'APSW',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'APSW',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'APSW',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'APSW',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'APSW',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'APSW',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'APSW',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'APSW',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'APSW',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'APSW',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'APSW',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'APSW',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'APSW',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'APSW',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // ISW Verfication
  {
    Verification: 'ISW',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'ISW',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'ISW',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'ISW',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'ISW',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'ISW',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'ISW',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'ISW',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'ISW',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'ISW',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'ISW',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'ISW',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'ISW',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'ISW',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'ISW',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'ISW',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // LCSW Verfication
  {
    Verification: 'LCSW',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'LCSW',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'LCSW',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'LCSW',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'LCSW',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'LCSW',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'LCSW',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'LCSW',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'LCSW',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'LCSW',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'LCSW',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'LCSW',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'LCSW',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'LCSW',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'LCSW',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'LCSW',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // SAC Verfication
  {
    Verification: 'SAC',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'SAC',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'SAC',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'SAC',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'SAC',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'SAC',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'SAC',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'SAC',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'SAC',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'SAC',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'SAC',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'SAC',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'SAC',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'SAC',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'SAC',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'SAC',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // COUNSELORS/MA, MS, MSE, MSW Verfication
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'COUNSELORS/MA, MS, MSE, MSW',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // LBA Verfication
  {
    Verification: 'LBA',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'LBA',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'LBA',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'LBA',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'LBA',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'LBA',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'LBA',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'LBA',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'LBA',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'LBA',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'LBA',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'LBA',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'LBA',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'LBA',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'LBA',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'LBA',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // SNM Verfication
  {
    Verification: 'SNM',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'SNM',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'SNM',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'SNM',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'SNM',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'SNM',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'SNM',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'SNM',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'SNM',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'SNM',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'SNM',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'SNM',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'SNM',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'SNM',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'SNM',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'SNM',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // AuD Verfication
  {
    Verification: 'AuD',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'AuD',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'AuD',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'AuD',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'AuD',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'AuD',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'AuD',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'AuD',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'AuD',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'AuD',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'AuD',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'AuD',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'AuD',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'AuD',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'AuD',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'AuD',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // PT Verfication
  {
    Verification: 'PT',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'PT',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'PT',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'PT',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'PT',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'PT',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'PT',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'PT',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'PT',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'PT',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'PT',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'PT',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'PT',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'PT',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'PT',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'PT',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // OT Verfication
  {
    Verification: 'OT',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'OT',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'OT',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'OT',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'OT',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'OT',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'OT',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'OT',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'OT',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'OT',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'OT',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'OT',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'OT',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'OT',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'OT',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'OT',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // SLP Verfication
  {
    Verification: 'SLP',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'SLP',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'SLP',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'SLP',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'SLP',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'SLP',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'SLP',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'SLP',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'SLP',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'SLP',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'SLP',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'SLP',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'SLP',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'SLP',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'SLP',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'SLP',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // RD Verfication
  {
    Verification: 'RD',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'RD',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'RD',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'RD',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'RD',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'RD',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'RD',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'RD',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'RD',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'RD',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'RD',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'RD',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'RD',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'RD',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'RD',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'RD',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // SURGICAL TECH Verfication
  {
    Verification: 'SURGICAL TECH',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'SURGICAL TECH',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // CNM Verfication
  {
    Verification: 'CNM',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'CNM',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'CNM',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'CNM',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'CNM',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'CNM',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'CNM',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'CNM',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'CNM',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'CNM',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'CNM',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'CNM',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'CNM',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'CNM',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'CNM',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'CNM',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  //BCBA
  {
    Verification: 'BCBA',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'BCBA',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'BCBA',
    Question: 'BoardCertification',
    Type: 'dropdown',
    Answers: ['CERTIFYING BOARD', 'BACB'],
    Disable: false,
  },
  {
    Verification: 'BCBA',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'BCBA',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'BCBA',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'BCBA',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'BCBA',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'BCBA',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'BCBA',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'BCBA',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'BCBA',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'BCBA',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'BCBA',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'BCBA',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'BCBA',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  //
  {
    Verification: 'RBT',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'RBT',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'RBT',
    Question: 'BoardCertification',
    Type: 'dropdown',
    Answers: ['CERTIFYING BOARD', 'BACB'],
    Disable: false,
  },
  {
    Verification: 'RBT',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'RBT',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'RBT',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'RBT',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'RBT',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'RBT',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'RBT',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'RBT',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'RBT',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'RBT',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'RBT',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'RBT',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'RBT',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
  // Medical Assistant Verfication
  {
    Verification: 'Medical Assistant',
    Question: 'ApplicableToYourFacility',
    Type: 'boolean',
    Answers: [],
    Disable: false,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'HighestLevelOfEducation',
    Type: 'dropdown',
    Answers: ['SCHOOL', 'NSC', 'STATE SITE'],
    Disable: false,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'BoardCertification',
    Type: 'string',
    Answers: ['CERTIFYING BOARD'],
    Disable: true,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'ProfessionalLicense',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'CDS',
    Type: 'string',
    Answers: ['STATE SITE'],
    Disable: true,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'DEA',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'MalpracticeFacesheet',
    Type: 'string',
    Answers: ['FACESHEET'],
    Disable: true,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'FSMBQuery',
    Type: 'string',
    Answers: ['FSMB'],
    Disable: true,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'HospitalPrivileges',
    Type: 'string',
    Answers: [''],
    Disable: true,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'InsuranceCoveragestatement',
    Type: 'string',
    Answers: ['COI'],
    Disable: true,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'NPDBContinuousQuery',
    Type: 'string',
    Answers: ['NPDB'],
    Disable: true,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'OIGQuery',
    Type: 'dropdown',
    Answers: ['OIG', 'NPDB'],
    Disable: false,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'MedicareOptOutList',
    Type: 'dropdown',
    Answers: ['OPT OUT', 'SAMS'],
    Disable: false,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'DeathRegister',
    Type: 'dropdown',
    Answers: ['VERIS', 'OTHER'],
    Disable: false,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'NPI',
    Type: 'string',
    Answers: ['NPPES'],
    Disable: true,
  },
  {
    Verification: 'Medical Assistant',
    Question: 'NSOW',
    Type: 'string',
    Answers: ['NSOW'],
    Disable: true,
  },
];

export const Areas_Of_Specialization = {
  partA: [
    {
      field: 'ADDADHD',
      title: 'ADD/ADHD',
    },
    {
      field: 'LGBTQA',
      title: 'LGBTQA issues',
    },
    {
      field: 'Addictions',
      title: 'Addictions, Non-Chemical (e.g. gambling)',
    },
    {
      field: 'Marital',
      title: 'Marital separation/Divorce',
    },
    {
      field: 'Adolescent',
      title: 'Adolescent issues',
    },
    {
      field: 'MedicalBehavioral',
      title: 'Medical/Behavioral comorbidity',
    },
    {
      field: 'AdoptionFoster',
      title: 'Adoption/Foster care/Attachment issues',
    },
    {
      field: 'MAT',
      title: 'Medication Assisted Treatment (MAT) (Suboxone Therapy)',
    },
    {
      field: 'AngerManagement',
      title: 'Anger management',
    },
    {
      field: 'MedicationManagement',
      title: 'Medication Management',
    },
    {
      field: 'AnxietyDisorders',
      title: 'Anxiety disorders',
    },
    {
      field: 'MenIssues',
      title: 'Men’s issues',
    },
    {
      field: 'AutisticDisordersAsperger',
      title: 'Autistic Disorders/Aspergers Syndrome',
    },
    {
      field: 'MHDevelopmentalDisabilityAdult',
      title: 'MH Developmental Disability (adult)',
    },
    {
      field: 'BehaviorManagementParentTraining',
      title: 'Behavior Management/Parent training,Ages 4-10',
    },
    {
      field: 'MHDevelopmentalDisabilityChild',
      title: 'MH Developmental Disability (child)',
    },
    {
      field: 'BehaviorManagementParentTrainingP11',
      title: 'Behavior Management/Parent training,Ages 11+',
    },
    {
      field: 'MilitaryMilitaryFamily',
      title: 'Military/Military Family',
    },
    {
      field: 'BilingualTherapy',
      title: 'Bilingual therapy',
    },
    {
      field: 'NeurocognitiveDisorders',
      title: 'Neurocognitive disorders',
    },
    {
      field: 'BingeEatingDisorder',
      title: 'Binge eating disorder',
    },
    {
      field: 'OCD',
      title: 'Obsessive Compulsive Disorder (OCD)',
    },
    {
      field: 'BipolarDisorder',
      title: 'Bipolar disorder',
    },
    {
      field: 'ODD',
      title: 'Oppositional defiant disorder (ODD)',
    },
    {
      field: 'BodyDsmorphicDisorder',
      title: 'Body dysmorphic disorder',
    },
    {
      field: 'PanicDisorder',
      title: 'Panic disorder',
    },
    {
      field: 'Bullying',
      title: 'Bullying',
    },
    {
      field: 'PerpetratorsOfViolenceAbuse',
      title: 'Perpetrators of Violence/Abuse',
    },
    {
      field: 'ChildhoodEatingDisorders',
      title: 'Childhood eating disorders (e.g. pica)',
    },
    {
      field: 'PersonalityDisorders',
      title: 'Personality disorders',
    },
    {
      field: 'ChronicPainIssues',
      title: 'Chronic pain issues',
    },
    {
      field: 'PostPartumDepression',
      title: 'Post-partum depression',
    },
    {
      field: 'ConductDisorders',
      title: 'Conduct disorders',
    },
    {
      field: 'PreschoolReadinessInfantMentalHealth',
      title: 'Preschool readiness/Infant mental health',
    },
    {
      field: 'CoOccurring',
      title: 'Co-occurring (MH/Substance abuse)',
    },
    {
      field: 'PrimarySubstanceAbuse',
      title: 'Primary substance abuse',
    },
    {
      field: 'CopingWithMedicalIssues',
      title:
        'Coping with Medical issues (e.g. cancer, Crohn’s, diabetes, obesity)',
    },
    {
      field: 'PsychoticDisorders',
      title: 'Psychotic disorders',
    },
    {
      field: 'CouplesCounseling',
      title: 'Couples counseling',
    },
    {
      field: 'PTSDAcute',
      title: 'PTSD, acute',
    },
  ],
  partB: [
    {
      field: 'CrossCulturalAdaptation',
      title: 'Cross-cultural adaptation',
    },
    {
      field: 'PTSDCchronic',
      title: 'PTSD, chronic',
    },
    {
      field: 'DepressiveDisorders',
      title: 'Depressive disorders',
    },
    {
      field: 'RefugeeIssues',
      title: 'Refugee issues',
    },
    {
      field: 'DevelopmentalDisabilities',
      title: 'Developmental Disabilities',
    },
    {
      field: 'SchizophrenicDisorders',
      title: 'Schizophrenic disorders',
    },
    {
      field: 'DissociativeIdentifyDisorders',
      title: 'Dissociative Identify Disorders',
    },
    {
      field: 'SelfharmBehaviors',
      title: 'Self-harm behaviors',
    },
    {
      field: 'DivorceBlendedFamilyIssues',
      title: 'Divorce/blended family issues',
    },
    {
      field: 'SeverelyAndPersistentlyMentallyIll',
      title: 'Severely and Persistently Mentally Ill',
    },
    {
      field: 'DomesticViolenceIntimatePartnerViolence',
      title: 'Domestic violence/Intimate Partner violence',
    },
    {
      field: 'SexualDysfunctions',
      title: 'Sexual dysfunctions',
    },
    {
      field: 'EatingDisordersAnorexiaBulimia',
      title: 'Eating Disorders, anorexia/bulimia',
    },
    {
      field: 'SexualPhysicalAbuseAdult',
      title: 'Sexual/Physical Abuse, adult',
    },
    {
      field: 'EducationalVocationalIssues',
      title: 'Educational/vocational issues',
    },
    {
      field: 'SexualPhysicalAbuseChild',
      title: 'Sexual/Physical Abuse, child',
    },
    {
      field: 'ElectiveMutism',
      title: 'Elective Mutism',
    },
    {
      field: 'SleepDisordersInsomnia',
      title: 'Sleep Disorders/Insomnia',
    },
    {
      field: 'EndOfLifeIssues',
      title: 'End of life issues',
    },
    {
      field: 'SocialSkillsTraining',
      title: 'Social Skills training',
    },
    {
      field: 'EnuresisEncopresis',
      title: 'Enuresis/encopresis',
    },
    {
      field: 'SomaticSymptomAndConversionDO',
      title: 'Somatic symptom and conversion d/o',
    },
    {
      field: 'ExcoriationDisorder',
      title: 'Excoriation disorder',
    },
    {
      field: 'SpecificLearningDisabilities',
      title: 'Specific learning disabilities',
    },
    {
      field: 'ExpertWitnessTestimony',
      title: 'Expert witness testimony',
    },
    {
      field: 'SpecificPhobias',
      title: 'Specific phobias',
    },
    {
      field: 'GeriatricAgingIssues',
      title: 'Geriatric/Aging issues',
    },
    {
      field: 'SubstanceAlcoholChemicalAbuse',
      title: 'Substance/Alcohol/Chemical Abuse',
    },
    {
      field: 'GeropsychiatryAlzheimers',
      title: 'Geropsychiatry/Alzheimers',
    },
    {
      field: 'TelementalHealth',
      title: 'Telemental Health',
    },
    {
      field: 'Griefloss',
      title: 'Grief/loss',
    },
    {
      field: 'TicdisordersTourettes',
      title: 'Tic disorders/Tourette’s',
    },
    {
      field: 'TBI',
      title: 'Head Trauma/Traumatic brain injury (TBI)',
    },
    {
      field: 'TMS',
      title: 'Transcranial Magnetic Stimulation (TMS)',
    },
    {
      field: 'Hoarding',
      title: 'Hoarding',
    },
    {
      field: 'TransgenderIssues',
      title: 'Transgender issues',
    },
    {
      field: 'HumanTrafficking',
      title: 'Human trafficking',
    },
    {
      field: 'Trichotillomania',
      title: 'Trichotillomania',
    },
    {
      field: 'InfertilityIssues',
      title: 'Infertility issues',
    },
    {
      field: 'WomensIssues',
      title: 'Women’s issues',
    },
    {
      field: 'OtherAreasOfSpecialization',
      title: 'Other',
    },
  ],
};
export const Therapeutic_Modalities = [
  {
    field: 'BehaviorModificationTherapy',
    title: 'Behavior Modification Therapy',
  },
  {
    field: 'CBT',
    title: 'CBT',
  },
  {
    field: 'BriefTherapy',
    title: 'Brief Therapy',
  },
  {
    field: 'DBT',
    title: 'DBT',
  },
  {
    field: 'ChildTherapy',
    title: 'Child Therapy',
  },
  {
    field: 'TFCBT',
    title: 'TF-CBT',
  },
  {
    field: 'ChildTherapyN5',
    title: 'Child Therapy (5 years old and younger)',
  },
  {
    field: 'EAP',
    title: 'EAP',
  },
  {
    field: 'CognitiveBehavioralTherapy',
    title: 'Cognitive Behavioral Therapy',
  },
  {
    field: 'CAP',
    title: 'CAP',
  },
  {
    field: 'DialecticalBehavioralTherapy',
    title: 'Dialectical Behavioral Therapy',
  },
  {
    field: 'CognitiveProcessingForTrauma',
    title: 'Cognitive Processing for trauma',
  },
  {
    field: 'FamilyTherapy',
    title: 'Family Therapy',
  },
  {
    field: 'LifeCoaching',
    title: 'Life coaching',
  },
  {
    field: 'GroupTherapy',
    title: 'Group Therapy',
  },
  {
    field: 'WorkersCompDisability',
    title: 'Worker’s Comp./Disability',
  },
  {
    field: 'InsightOrientedPsychodynamicTherapy',
    title: 'Insight-oriented/psychodynamic therapy',
  },
  {
    field: 'BriefSolutionFocused',
    title: 'Brief Solution Focused',
  },
  {
    field: 'PlayTherapy',
    title: 'Play Therapy',
  },
  {
    field: 'FitnessForDutyAssessments',
    title: 'Fitness for duty assessments',
  },
  {
    field: 'SolutionFocusedTherapy',
    title: 'Solution Focused Therapy',
  },
  {
    field: 'BariatricAssessments',
    title: 'Bariatric assessments',
  },
  {
    field: 'TargetedCaseManagement',
    title: 'Targeted Case Management',
  },
  {
    field: 'FaithBasedCounselingFaith',
    title: 'Faith-based counseling faith',
  },
  {
    field: 'PsychologicalTesting',
    title: 'Psychological Testing',
  },
  {
    field: 'RedCrossDisasterMH',
    title: 'Red Cross Disaster MH',
  },
  {
    field: 'OtherTherapeuticModalities',
    title: 'Other',
  },
];

export const Specialty_Certifications = [
  {
    field: 'SpecialtyCertificationsCBT',
    title: 'CBT',
  },
  {
    field: 'SpecialtyCertificationsDBT',
    title: 'DBT',
  },
  {
    field: 'TTCBT',
    title: 'TT-CBT',
  },
  {
    field: 'SpecialtyCertificationsEAP',
    title: 'EAP',
  },
  {
    field: 'SpecialtyCertificationsCAP',
    title: 'CAP',
  },
  {
    field: 'SpecialtyCertificationsCognitiveProcessingForTrauma',
    title: 'Cognitive Processing for Trauma',
  },
  {
    field: 'SpecialtyCertificationsLifeCoaching',
    title: 'Life Coaching',
  },
  {
    field: 'WorkerCompDisability',
    title: 'Workers Comp/Disability',
  },
  {
    field: 'SpecialtyCertificationsBriefSolutionFocused',
    title: 'Brief Solution Focused',
  },
  {
    field: 'FitnessForDutyAssignments',
    title: 'Fitness for Duty Assignments',
  },
  {
    field: 'SpecialtyCertificationsBariatricAssessments',
    title: 'Bariatric Assessments',
  },
  {
    field: 'FaithBasedCounseling',
    title: 'Faith Based Counseling',
  },
  {
    field: 'SpecialtyCertificationsRedCrossDisasterMH',
    title: 'Red Cross Disaster MH',
  },
  {
    field: 'SpecialtyCertificationsOther',
    title: 'Other',
  },
];
export const Ages_Treated = [
  {
    field: 'AllAges',
    title: 'All Ages',
  },
  {
    field: 'ChildrenA',
    title: 'Children 0-5',
  },
  {
    field: 'ChildrenB',
    title: 'Children 6-12s',
  },
  {
    field: 'Adolescents',
    title: 'Adolescents 13-17',
  },
  {
    field: 'Adults',
    title: 'Adults 18+',
  },
  {
    field: 'Geriatrics',
    title: 'Geriatrics 70+',
  },
];
export const PAGE_SIZES = {
  pageSizes: [
    {
      value: 10,
      display: '10',
    },
    {
      value: 30,
      display: '30',
    },
    {
      value: 50,
      display: '50',
    },
    {
      value: 100,
      display: '100',
    },
    {
      value: 500,
      display: '500',
    },
  ],
  spanPages: 2,
};
export const ASSIGNS = ['Verfying', 'Enrolling', 'Inactive'];
export const REASON = [
  'Speciality does not require admitting privileges',
  'Midlevel Provider-another admits on my behalf',
  'Admit to the nearest ER',
  'Admitting agreement with facility  ',
];
export const PRIVILEGETYPE = [
  'Speciality does not require  privileges',
  'Refer TO ER',
  'Admitting Agreement with Individual',
  'Admitting Agreement with Group',
];
export const REASONMODEL = [
  { Reason: 'speciality is' },
  { Reason: 'midlevel is' },
  { Reason: 'admit is' },
  { Reason: 'admitting is' },
];
export const PRIVILEGETYPESMODEL = [
  { privilageType: 'speciality does' },
  { privilageType: 'refer TO ER' },
  { privilageType: 'individual' },
  { privilageType: 'Group' },
];

export const ACCESSLEVEL = [
  'ADMIN',
  'DIRECTOR',
  'COORDINATOR',
  'SPECIALIST',
  'CLERICAL',
  'ENROLLMENT_READONLY',
];

export const ROLESDATATABLECOLUMNS = [
  'ACCESS TO',
  'ADMIN',
  'DIRECTOR',
  'COORDINATOR',
  'SPECIALIST',
  'CLERICAL',
  'ENROLLMENT_READONLY',
];

export const ROLESDATATABLE: any[] = [
  {
    data: {
      'ACCESS TO': 'View Enrollment Tracking',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 1,
      ENROLLMENT_READONLY: 1,
    },
  },
  {
    data: {
      'ACCESS TO': 'View PSV',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 1,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Download/Print PSV',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 1,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'View Org Profile',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 1,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'View Provider Summary/Snapshot',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 1,
      ENROLLMENT_READONLY: 1,
    },
  },
  {
    data: {
      'ACCESS TO': 'View Provider Documents',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 1,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Access Forms Library',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 0,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Add Org Users',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 0,
      CLERICAL: 0,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Edit Org Profile',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 0,
      CLERICAL: 0,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Add Org Contacts/Payers',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 0,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Accept Provider Affiliation',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 0,
      CLERICAL: 0,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Add Provider',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 0,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Tag Providers by Location',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 0,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Enroll Providers',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 0,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Org Settings',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 0,
      CLERICAL: 0,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Edit Enrollment Tracking',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 1,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Edit PSV',
      ADMIN: 1,
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 0,
      ENROLLMENT_READONLY: 0,
    },
  },
  {
    data: {
      'ACCESS TO': 'Location Label to view only those providers',
      ADMIN: 'Vievs all providers',
      DIRECTOR: 1,
      COORDINATOR: 1,
      SPECIALIST: 1,
      CLERICAL: 1,
      ENROLLMENT_READONLY: 1,
    },
  },
];

export const ALL_SPECIALTIES = {
  ...MD_DO,
  ...DDS_DMD,
  ...DC,
  ...DPM,
  ...ALLIED_PROVIDER_TYPE,
};

export const MD_DPM_MERGED = { ...MD_DO, ...DPM };

export const PROVIDER_STATUSES = {
  NEW: 'New',
  ACTIVE: 'Active',
  ACTIVE_PROVISIONAL: 'Active-Provisional',
  APPLICANT: 'Applicant',
  ACTIVE_LOA: 'Active-LOA',
  ACTIVE_CONSULTING: 'Consulting',
  ACTIVE_COURTESY: 'Courtesy',
  ACTIVE_LOCUMS: 'Active-Locums',
  ACTIVE_TELEMEDICINE: 'Active-Telemedicine',
  COURTESY_PROVISIONAL: 'Courtesy-Provisional',
  HONORARY: 'Honorary',
  MEMBER: 'Member',
  PRECEPTOR: 'Preceptor',
  RESIDENT: 'Resident',
  RESIDENT_IN_WAITING: 'Resident-in-waiting',
  // HONORARY_RETIRED: 'Honorary-Retired'
  // INACTIVE: "Inactive",
  // ARCHIVE: "Archive",
};

export const MILITARY_BRANCHES = [
  'Army',
  'Navy',
  'Air Force',
  'Coast Guard',
  'Marine Corps',
  'Space Force',
];

export const DRUG_SCHEDULES_TYPE = {
  '002': '2',
  '002N': '2N',
  '003': '3',
  '003N': '3N',
  '004': '4',
  '005': '5',
};

export const DRUG_SCHEDULES_TYPE_CDS = {
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
};

export const PG_DEGREES = {
  DBA: 'DBA',
  DHA: 'DHA',
  DIT: 'DIT',
  DP: 'DP',
  DPH: 'DPH',
  DSW: 'DSW',
  EdD: 'EdD',
  JD: 'JD',
  LPN: 'LPN',
  OD: 'OD',
  PharmD: 'PharmD',
  PhD: 'PhD',
  PMC: 'PMC',
  POD: 'POD',
  PsyD: 'PsyD',
  RN: 'RN',
  DC: 'Doctor of Chiropractic (DC)',
  DDS: 'Doctor of Dental Surgery (DDS)',
  DMD: 'Doctor of Medicine in Dentistry (DMD)',
  DO: 'Doctor of Osteopathic Medicine (DO)',
  DPM: 'Doctor of Podiatric Medicine (DPM)',
  EMBA: 'Executive Master of Business Administration  (EMBA)',
  MAcc: 'Master of Accounting  (MAcc)',
  MAEng: 'Master of Aerospace Engineering  (MAEng)',
  MAE: 'Master of Agricultural Economics  (MAE)',
  MAExt: 'Master of Agricultural Extension  (MAExt)',
  MAg: 'Master of Agriculture  (MAg)',
  MAA: 'Master of Applied Anthropology  (MAA)',
  MArE: 'Master of Architectural Engineering  (MArE)',
  MArch: 'Master of Architecture  (MArch)',
  MA: 'Master of Arts (MA/AM)',
  MAAEd: 'Master of Arts in Art Education  (MAAEd)',
  MAAT: 'Master of Arts in Art Therapy  (MAAT)',
  MAAA: 'Master of Arts in Arts Administration  (MAAA)',
  MABS: 'Master of Arts in Behavioral Science  (MABS)',
  MACT: 'Master of Arts in College Teaching  (MACT)',
  MACom: 'Master of Arts in Communications  (MAC)',
  MAComP: 'Master of Arts in Community Psychology  (MACP)',
  MACE: 'Master of Arts in Computer Education  (MACE)',
  MAC: 'Master of Arts in Counseling  (MAC)',
  MACP: 'Master of Arts in Counseling Psychology  (MACP)',
  MACJ: 'Master of Arts in Criminal Justice (MACJ)',
  MACCS: 'Master of Arts in Cross-Cultural Studies  (MACCS)',
  MAFC: 'Master of Arts in Family Counseling  (MAFC)',
  MAJ: 'Master of Arts in Journalism  (MAJ)',
  MALS: 'Master of Arts in Liberal Studies  (MALS)',
  MAPT: 'Master of Arts in Physical Therapy  (MAPT)',
  MAT: 'Master of Arts in Teaching  (MAT)',
  MAH: 'Master of Arts in the Humanities  (MAH)',
  MAEd: 'Master of Arts/Science in Education  (MAEd)',
  MB: 'Master of Bioinformatics  (MB)',
  MBA: 'Master of Business Administration  (MBA)',
  MSChE: 'Master of Chemical Engineering  (MSChE)',
  MCRP: 'Master of City and Regional Planning (MCRP)',
  MCP: 'Master of City Planning  (MCP)',
  MCivE: 'Master of Civil Engineering  (MCE)',
  MCMS: 'Master of Clinical Medical Science  (MCMS)',
  MCH: 'Master of Community Health  (MCH)',
  MCL: 'Master of Comparative Law  (MCL)',
  MCE: 'Master of Computer Engineering  (MCE)',
  MCS: 'Master of Computer Science  (MCS)',
  MCJ: 'Master of Criminal Justice  (MCJ)',
  MDes: 'Master of Design  (MDes)',
  MDiv: 'Master of Divinity  (MDiv)',
  MEd: 'Master of Education  (MEd)',
  MECE: 'Master of Electrical and Computer Engineering  (MECE)',
  MEE: 'Master of Electrical Engineering  (MEE)',
  'ME/MEngMaster of Engineering  (': ')ME/MEng',
  MEM: 'Master of Engineering Management  (MEM)',
  MEngS: 'Master of Engineering Science  (MES)',
  MET: 'Master of Engineering Technology  (MET)',
  MED: 'Master of Environmental Design  (MED)',
  MEP: 'Master of Environmental Planning  (MEP)',
  MES: 'Master of Environmental Science/Studies  (MES)',
  MFT: 'Master of Family Therapy  (MFT)',
  MFA: 'Master of Fine Arts  (MFA)',
  MFE: 'Master of Forest Engineering  (MFE)',
  MFR: 'Master of Forest Resources  (MFR)',
  MF: 'Master of Forestry  (MF)',
  MGE: 'Master of Geological Engineering  (MGE)',
  MHA: 'Master of Health Administration  (MHA)',
  MHCA: 'Master of Health Care Administration  (MHCA)',
  MSHCA: 'Master of Health Care Administration  (MSHCA)',
  MHCM: 'Master of Health Care Management  (MHCM)',
  MHSA: 'Master of Health Services Administration  (MHSA)',
  MHL: 'Master of Hebrew Letters  (MHL)',
  MHP: 'Master of Heritage/Historical Preservation  (MHP)',
  MHE: 'Master of Home Economics  (MHE)',
  MHM: 'Master of Hospitality Management  (MHM)',
  MHRD: 'Master of Human Resource Development  (MHRD)',
  MHR: 'Master of Human Resources  (MHR)',
  MIS: 'Master of Individualized/Interdisciplinary Studies  (MIS)',
  MID: 'Master of Industrial Design  (MID)',
  MIE: 'Master of Industrial Engineering  (MIE)',
  MIRM: 'Master of Information Resources Management  (MIRM)',
  MIT: 'Master of Information Technology  (MIT)',
  MIA: 'Master of International Affairs  (MIA)',
  MIB: 'Master of International Business/Business Administration  (MIB)',
  MJ: 'Master of Journalism  (MJ)',
  MJA: 'Master of Justice Administration  (MJA)',
  MLArch: 'Master of Landscape Architecture  (MLArch)',
  MLL: 'Master of Law Librarianship  (MLL)',
  LLM: 'Master of Laws  (LLM)',
  MLStd: 'Master of Liberal Studies  (MLS)',
  MLIS: 'Master of Library and Information Science  (MLIS)',
  MLS: 'Master of Library Science  (MLS)',
  MLitM: 'Master of Liturgical Music  (MLitM)',
  MMng: 'Master of Management  (MM)',
  MManE: 'Master of Manufacturing Engineering  (MME)',
  MMM: 'Master of Manufacturing Management  (MMM)',
  MMA: 'Master of Marine Affairs  (MMA)',
  MMFT: 'Master of Marriage and Family Therapy  (MMFT)',
  MMC: 'Master of Mass Communications  (MMC)',
  MMS: 'Master of Materials Science  (MMS)',
  MMSE: 'Master of Materials Science and Engineering (MMSE)',
  MME: 'Master of Mechanical Engineering  (MME)',
  MM: 'Master of Music  (MM)',
  MMEd: 'Master of Music Education  (MMEd)',
  MMT: 'Master of Music Therapy  (MMT)',
  MN: 'Master of Nursing  (MN)',
  MNA: 'Master of Nursing Administration  (MNA)',
  MOT: 'Master of Occupational Therapy  (MOT)',
  MPharm: 'Master of Pharmacy  (MPharm)',
  MPhil: 'Master of Philosophy  (MPhil)',
  MPEd: 'Master of Physical Education  (MPEd)',
  MPT: 'Master of Physical Therapy  (MPT)',
  MP: 'Master of Planning  (MP)',
  MPA: 'Master of Professional Accounting  (MPA)',
  MPAdm: 'Master of Public Administration  (MPA)',
  MPIA: 'Master of Public and International Affairs  (MPIA)',
  MPH: 'Master of Public Health  (MPH)',
  MPHE: 'Master of Public Health Education  (MPHE)',
  MPM: 'Master of Public Management  (MPM)',
  MPP: 'Master of Public Policy  (MPP)',
  MRED: 'Master of Real Estate Development  (MRED)',
  MRA: 'Master of Recreation Administration  (MRA)',
  MRPA: 'Master of Recreation and Parks Administration  (MRPA)',
  MRP: 'Master of Regional Planning  (MRP)',
  MRC: 'Master of Rehabilitation Counseling  (MRC)',
  MRM: 'Master of Rehabilitation Medicine  (MRM)',
  MRelEd: 'Master of Religious Education  (MRelEd)',
  MSM: 'Master of Sacred Music  (MSM)',
  STM: 'Master of Sacred Theology  (STM)',
  MS: 'Master of Science  (MS)',
  MSAA: 'Master of Science in Aeronautics and Astronautics  (MSAA)',
  MSAI: 'Master of Science in Artificial Intelligence  (MSAI)',
  MSBE: 'Master of Science in Biomedical Engineering  (MSBE)',
  MSBA: 'Master of Science in Business Administration (MSBA)',
  MSCerE: 'Master of Science in Ceramic Engineering  (MSCerE)',
  MSC: 'Master of Science in Counseling (MSC)',
  MSCP: 'Master of Science in Counseling Psychology (MSCP)',
  MSCJ: 'Master of Science in Criminal Justice (MSCJ)',
  MSD: 'Master of Science in Dentistry  (MSD)',
  MSE: 'Master of Science in Economics  (MSE)',
  MSEd: 'Master of Science in Education (MSEd)',
  MSEng: 'Master of Science in Engineering  (MSE)',
  MSEnvE: 'Master of Science in Environmental Engineering  (MSEnvE)',
  MSESS: 'Master of Science in Exercise and Sport Studies  (MSESS)',
  MSFin: 'Master of Science in Finance  (MSF)',
  MSFS: 'Master of Science in Forensic Science  (MSFS)',
  MSF: 'Master of Science in Forestry  (MSF)',
  MSGC: 'Master of Science in Genetic Counseling  (MSGC)',
  MSG: 'Master of Science in Gerontology  (MSG)',
  MSHA: 'Master of Science in Health Administration (MSHA)',
  MSHR: 'Master of Science in Human Resources  (MSHR)',
  MSIS: 'Master of Science in Information Science/Systems  (MSIS)',
  MSJ: 'Master of Science in Journalism (MSJ)',
  MSLS: 'Master of Science in Library Science  (MSLS)',
  MSMan: 'Master of Science in Management  (MSM)',
  MSMC: 'Master of Science in Mass Communications  (MSMC)',
  MSMI: 'Master of Science in Medical Illustration  (MSMI)',
  MSMT: 'Master of Science in Medical Technology  (MSMT)',
  MSMetE: 'Master of Science in Metallurgical Engineering  (MSMetE)',
  MSNS: 'Master of Science in Natural Science  (MSNS)',
  MSNE: 'Master of Science in Nuclear Engineering  (MSNE)',
  MSNA: 'Master of Science in Nurse Anesthesia  (MSNA)',
  MSN: 'Master of Science in Nursing  (MSN)',
  MSO: 'Master of Science in Orthodontics  (MSO)',
  MSPetE: 'Master of Science in Petroleum Engineering  (MSPetE)',
  MSPS: 'Master of Science in Pharmaceutical Sciences  (MSPS)',
  MSPT: 'Master of Science in Physical Therapy (MSPT)',
  MSPA: 'Master of Science in Public Administration  (MSPA)',
  MSPH: 'Master of Science in Public Health  (MSPH)',
  MST: 'Master of Science in Teaching (MST)',
  MSText: 'Master of Science in Textiles  (MSText)',
  MSW: 'Master of Social Work  (MSW)',
  MT: 'Master of Taxation  (MT)',
  MTech: 'Master of Technology  (MTech)',
  ThM: 'Master of Theology  (ThM)',
  MTM: 'Master of Tourism Management  (MTM)',
  MURP: 'Master of Urban and Regional Planning  (MURP)',
  MUD: 'Master of Urban Design  (MUD)',
  MUP: 'Master of Urban Planning  (MUP)',
  MUPP: 'Master of Urban Planning and Policy  (MUPP)',
  MUS: 'Master of Urban Studies  (MUS)',
  MVS: 'Master of Veterinary Science  (MVS)',
  MWS: 'Master of Women’s Studies  (MWS)',
  MD: 'Medical Doctor (MD)',
  'Master of Physician Assistant Studies':
    'Master of Physician Assistant Studies',
  DNP: 'Doctor of Nursing Practice',
};

export const UG_DEGREES = {
  'High School Diploma': 'High School Diploma',
  'Associate Degree in Administration of Justice':
    'Associate Degree in Administration of Justice',
  'Associate Degree in Animal Management':
    'Associate Degree in Animal Management',
  'Associate Degree in Architectural Building Engineering Technology':
    'Associate Degree in Architectural Building Engineering Technology',
  'Associate Degree in Architecture and Career Options':
    'Associate Degree in Architecture and Career Options',
  'Associate Degree in Art': 'Associate Degree in Art',
  'Associate Degree in Automotive Maintenance Technology':
    'Associate Degree in Automotive Maintenance Technology',
  'Associate Degree in Aviation Mechanics':
    'Associate Degree in Aviation Mechanics',
  'Associate Degree in Behavioral Science':
    'Associate Degree in Behavioral Science',
  'Associate Degree in Boat Mechanics': 'Associate Degree in Boat Mechanics',
  'Associate Degree in Boat Repair and Maintenance':
    'Associate Degree in Boat Repair and Maintenance',
  'Associate Degree in Cabinet Design Technology':
    'Associate Degree in Cabinet Design Technology',
  'Associate Degree in Child Development: Program Summary':
    'Associate Degree in Child Development: Program Summary',
  'Associate Degree in Christian Ministry':
    'Associate Degree in Christian Ministry',
  'Associate Degree in Cosmetology Business':
    'Associate Degree in Cosmetology Business',
  'Associate Degree in Digital Media': 'Associate Degree in Digital Media',
  'Associate Degree in Early Childhood Special Education':
    'Associate Degree in Early Childhood Special Education',
  'Associate Degree in Elementary Education':
    'Associate Degree in Elementary Education',
  'Associate Degree in English': 'Associate Degree in English',
  'Associate Degree in Environmental Science':
    'Associate Degree in Environmental Science',
  'Associate Degree in Environmental Studies':
    'Associate Degree in Environmental Studies',
  'Associate Degree in General Psychology':
    'Associate Degree in General Psychology',
  'Associate Degree in History and Information':
    'Associate Degree in History and Information',
  'Associate Degree in Interdisciplinary Studies':
    'Associate Degree in Interdisciplinary Studies',
  'Associate Degree in International Relations':
    'Associate Degree in International Relations',
  'Associate Degree in Landscape Architecture':
    'Associate Degree in Landscape Architecture',
  'Associate Degree in Landscaping Design':
    'Associate Degree in Landscaping Design',
  'Associate Degree in Library Science': 'Associate Degree in Library Science',
  'Associate Degree in Music': 'Associate Degree in Music',
  'Associate Degree in Wildlife Management':
    'Associate Degree in Wildlife Management',
  'Associate Degree in Education': 'Associate Degree in Education',
  'Associate of Applied Science in Accelerated Culinary Arts':
    'Associate of Applied Science in Accelerated Culinary Arts',
  'Associate of Applied Science in Accounting Specialist':
    'Associate of Applied Science in Accounting Specialist',
  'Associate of Applied Science in Administrative Support':
    'Associate of Applied Science in Administrative Support',
  'Associate of Applied Science in Baking and Pastry':
    'Associate of Applied Science in Baking and Pastry',
  'Associate of Applied Science  in Business Administration':
    'Associate of Applied Science  in Business Administration',
  'Associate of Applied Science in Business Administration - Finance':
    'Associate of Applied Science in Business Administration - Finance',
  'Associate of Applied Science in Business Information Systems':
    'Associate of Applied Science in Business Information Systems',
  'Associate of Applied Science in Civil Justice - Law Enforcement':
    'Associate of Applied Science in Civil Justice - Law Enforcement',
  'Associate of Applied Science in Clinical Medical Assisting':
    'Associate of Applied Science in Clinical Medical Assisting',
  'Associate of Applied Science in Computer Applications':
    'Associate of Applied Science in Computer Applications',
  'Associate of Applied Science in Computer Electronics':
    'Associate of Applied Science in Computer Electronics',
  'Associate of Applied Science  in Computer Game Design':
    'Associate of Applied Science  in Computer Game Design',
  'Associate of Applied Science in Computer Information Systems':
    'Associate of Applied Science in Computer Information Systems',
  'Associate of Applied Science in Culinary Arts':
    'Associate of Applied Science in Culinary Arts',
  'Associate of Applied Science in Digital Media Communications':
    'Associate of Applied Science in Digital Media Communications',
  'Associate of Applied Science in Digital Photography':
    'Associate of Applied Science in Digital Photography',
  'Associate of Applied Science in Electronic Engineering':
    'Associate of Applied Science in Electronic Engineering',
  'Associate of Applied Science in Emergency Medical Services':
    'Associate of Applied Science in Emergency Medical Services',
  'Associate of Applied Science in Health Care Management':
    'Associate of Applied Science in Health Care Management',
  'Associate of Applied Science in Health Information Management':
    'Associate of Applied Science in Health Information Management',
  'Associate of Applied Science in Healthcare Administration':
    'Associate of Applied Science in Healthcare Administration',
  'Associate of Applied Science in Legal Office E-ministration':
    'Associate of Applied Science in Legal Office E-ministration',
  'Associate of Applied Science in Telecommunications Technology':
    'Associate of Applied Science in Telecommunications Technology',
  'Associate of Applied Science in Television Production':
    'Associate of Applied Science in Television Production',
  'Associate of Applied Science in Visual Communications':
    'Associate of Applied Science in Visual Communications',
  'Associate of Applied Science in Nursing':
    'Associate of Applied Science in Nursing',
  'Associate of Applied Science in Dental Hygiene':
    'Associate of Applied Science in Dental Hygiene',
  'Associate of Arts in Computer Information Systems':
    'Associate of Arts in Computer Information Systems',
  'Associate of Arts in Internetworking Technology':
    'Associate of Arts in Internetworking Technology',
  'Associate of Arts in Psychology': 'Associate of Arts in Psychology',
  'Associate of Arts in Interior Architecture and Design':
    'Associate of Arts in Interior Architecture and Design',
  'Associate of Biotechnology': 'Associate of Biotechnology',
  'Associate of Business Science in Individualized Studies':
    'Associate of Business Science in Individualized Studies',
  'Associate of Early Childhood Education':
    'Associate of Early Childhood Education',
  'Associate of Occupational Studies in Legal Office Administration':
    'Associate of Occupational Studies in Legal Office Administration',
  'Associate of Science in Computer Information Science':
    'Associate of Science in Computer Information Science',
  'Associate of Science in Computer Science':
    'Associate of Science in Computer Science',
  'Associate of Science in Corrections, Probation, & Parole':
    'Associate of Science in Corrections, Probation, & Parole',
  'Associate of Science in Electronics Engineering Technology':
    'Associate of Science in Electronics Engineering Technology',
  'Associate of Science in Interactive & Graphic Art':
    'Associate of Science in Interactive & Graphic Art',
  'Associate of Science in Industrial Maintenance Technology':
    'Associate of Science in Industrial Maintenance Technology',
  'Associate of Arts and science': 'Associate of Arts and science',
  'Bachelor of Architecture ': 'Bachelor of Architecture ',
  'Bachelor of Arts ': 'Bachelor of Arts ',
  'Bachelor of Biomedical Engineering ': 'Bachelor of Biomedical Engineering ',
  'Bachelor of Business ': 'Bachelor of Business ',
  'Bachelor of Business Administration ':
    'Bachelor of Business Administration ',
  'Bachelor of Canon Law ': 'Bachelor of Canon Law ',
  'Bachelor of Civil Engineering ': 'Bachelor of Civil Engineering ',
  'Bachelor of Computer Engineering ': 'Bachelor of Computer Engineering ',
  'Bachelor of Computer Science ': 'Bachelor of Computer Science ',
  'Bachelor of Criminal Justice ': 'Bachelor of Criminal Justice ',
  'Bachelor of Divinity ': 'Bachelor of Divinity ',
  'Bachelor of Education ': 'Bachelor of Education ',
  'Bachelor of Electrical Engineering ': 'Bachelor of Electrical Engineering ',
  'Bachelor of Engineering ': 'Bachelor of Engineering ',
  'Bachelor of Engineering Technology ': 'Bachelor of Engineering Technology ',
  'Bachelor of Fiber Engineering ': 'Bachelor of Fiber Engineering ',
  'Bachelor of Fine Arts ': 'Bachelor of Fine Arts ',
  'Bachelor of Forestry ': 'Bachelor of Forestry ',
  'Bachelor of Hebrew Letters ': 'Bachelor of Hebrew Letters ',
  'Bachelor of Journalism ': 'Bachelor of Journalism ',
  'Bachelor of Laws ': 'Bachelor of Laws ',
  'Bachelor of Liberal Studies ': 'Bachelor of Liberal Studies ',
  'Bachelor of Literature ': 'Bachelor of Literature ',
  'Bachelor of Marine Science ': 'Bachelor of Marine Science ',
  'Bachelor of Mechanical Engineering ': 'Bachelor of Mechanical Engineering ',
  'Bachelor of Music ': 'Bachelor of Music ',
  'Bachelor of Music in Composition': 'Bachelor of Music in Composition',
  'Bachelor of Music in Jazz Studies': 'Bachelor of Music in Jazz Studies',
  'Bachelor of Music in Music Education':
    'Bachelor of Music in Music Education',
  'Bachelor of Music in Performance': 'Bachelor of Music in Performance',
  'Bachelor of Music in Theory': 'Bachelor of Music in Theory',
  'Bachelor of Nursing ': 'Bachelor of Nursing ',
  'Bachelor of Pharmacy ': 'Bachelor of Pharmacy ',
  'Bachelor of Philosophy ': 'Bachelor of Philosophy ',
  'Bachelor of Physical Education': 'Bachelor of Physical Education',
  'Bachelor of Religious Education ': 'Bachelor of Religious Education ',
  'Bachelor of Science ': 'Bachelor of Science ',
  'Bachelor of Science in Aerospace Engineering ':
    'Bachelor of Science in Aerospace Engineering ',
  'Bachelor of Science in Agricultural Engineering ':
    'Bachelor of Science in Agricultural Engineering ',
  'Bachelor of Science in Biological Engineering ':
    'Bachelor of Science in Biological Engineering ',
  'Bachelor of Science in Biological Systems ':
    'Bachelor of Science in Biological Systems ',
  'Bachelor of Science in Biomedical Engineering ':
    'Bachelor of Science in Biomedical Engineering ',
  'Bachelor of Science in Biosystems and Agricultural Engineering ':
    'Bachelor of Science in Biosystems and Agricultural Engineering ',
  'Bachelor of Science in Business ': 'Bachelor of Science in Business ',
  'Bachelor of Science in Chemical and Biomolecular Engineering ':
    'Bachelor of Science in Chemical and Biomolecular Engineering ',
  'Bachelor of Science in Chemical and Materials Engineering ':
    'Bachelor of Science in Chemical and Materials Engineering ',
  'Bachelor of Science in Chemical Engineering ':
    'Bachelor of Science in Chemical Engineering ',
  'Bachelor of Science in Chemistry ': 'Bachelor of Science in Chemistry ',
  'Bachelor of Science in Civil and Infrastructure Engineering ':
    'Bachelor of Science in Civil and Infrastructure Engineering ',
  'Bachelor of Science in Civil Engineering ':
    'Bachelor of Science in Civil Engineering ',
  'Bachelor of Science in Civil Engineering Technology ':
    'Bachelor of Science in Civil Engineering Technology ',
  'Bachelor of Science in Computer Engineering ':
    'Bachelor of Science in Computer Engineering ',
  'Bachelor of Science in Computer Engineering Technology ':
    'Bachelor of Science in Computer Engineering Technology ',
  'Bachelor of Science in Computer Science ':
    'Bachelor of Science in Computer Science ',
  'Bachelor of Science in Computer Science and Engineering ':
    'Bachelor of Science in Computer Science and Engineering ',
  'Bachelor of Science in Construction Engineering Technology ':
    'Bachelor of Science in Construction Engineering Technology ',
  'Bachelor of Science in Criminal Justice ':
    'Bachelor of Science in Criminal Justice ',
  'Bachelor of Science in Drafting Design Technology ':
    'Bachelor of Science in Drafting Design Technology ',
  'Bachelor of Science in Education ': 'Bachelor of Science in Education ',
  'Bachelor of Science in Electrical and Computer Engineering ':
    'Bachelor of Science in Electrical and Computer Engineering ',
  'Bachelor of Science in Electrical Engineering ':
    'Bachelor of Science in Electrical Engineering ',
  'Bachelor of Science in Electrical/Electronics Technology ':
    'Bachelor of Science in Electrical/Electronics Technology ',
  'Bachelor of Science in Electro-Mechanical Engineering Technology ':
    'Bachelor of Science in Electro-Mechanical Engineering Technology ',
  'Bachelor of Science in Engineering ': 'Bachelor of Science in Engineering ',
  'Bachelor of Science in Engineering Management ':
    'Bachelor of Science in Engineering Management ',
  'Bachelor of Science in Engineering Technology ':
    'Bachelor of Science in Engineering Technology ',
  'Bachelor of Science in Environmental Engineering ':
    'Bachelor of Science in Environmental Engineering ',
  'Bachelor of Science in Foreign Service':
    'Bachelor of Science in Foreign Service',
  'Bachelor of Science in Forensic Science':
    'Bachelor of Science in Forensic Science',
  'Bachelor of Science in Forest Research ':
    'Bachelor of Science in Forest Research ',
  'Bachelor of Science in Forestry': 'Bachelor of Science in Forestry',
  'Bachelor of Science in Health Services Administration':
    'Bachelor of Science in Health Services Administration',
  'Bachelor of Science in History': 'Bachelor of Science in History',
  'Bachelor of Science in Hospitality Management':
    'Bachelor of Science in Hospitality Management',
  'Bachelor of Science in Human Resources Management':
    'Bachelor of Science in Human Resources Management',
  'Bachelor of Science in Industrial Engineering ':
    'Bachelor of Science in Industrial Engineering ',
  'Bachelor of Science in Information Systems':
    'Bachelor of Science in Information Systems',
  'Bachelor of Science in Information Technology':
    'Bachelor of Science in Information Technology',
  'Bachelor of Science in Integrated Science':
    'Bachelor of Science in Integrated Science',
  'Bachelor of Science in International Relations':
    'Bachelor of Science in International Relations',
  'Bachelor of Science in Journalism': 'Bachelor of Science in Journalism',
  'Bachelor of Science in Legal Management':
    'Bachelor of Science in Legal Management',
  'Bachelor of Science in Management': 'Bachelor of Science in Management',
  'Bachelor of Science in Manufacturing Engineering':
    'Bachelor of Science in Manufacturing Engineering',
  'Bachelor of Science in Manufacturing Systems Engineering ':
    'Bachelor of Science in Manufacturing Systems Engineering ',
  'Bachelor of Science in Marketing': 'Bachelor of Science in Marketing',
  'Bachelor of Science in Materials Engineering ':
    'Bachelor of Science in Materials Engineering ',
  'Bachelor of Science in Materials Science and Engineering ':
    'Bachelor of Science in Materials Science and Engineering ',
  'Bachelor of Science in Mathematics': 'Bachelor of Science in Mathematics',
  'Bachelor of Science in Mechanical Engineering':
    'Bachelor of Science in Mechanical Engineering',
  'Bachelor of Science in Mechanical Engineering Technology ':
    'Bachelor of Science in Mechanical Engineering Technology ',
  'Bachelor of Science in Medical Technology':
    'Bachelor of Science in Medical Technology',
  'Bachelor of Science in Metallurgical Engineering':
    'Bachelor of Science in Metallurgical Engineering',
  'Bachelor of Science in Meteorology': 'Bachelor of Science in Meteorology',
  'Bachelor of Science in Microbiology': 'Bachelor of Science in Microbiology',
  'Bachelor of Science in Mining Engineering':
    'Bachelor of Science in Mining Engineering',
  'Bachelor of Science in Molecular Biology':
    'Bachelor of Science in Molecular Biology',
  'Bachelor of Science in Neuroscience': 'Bachelor of Science in Neuroscience',
  'Bachelor of Science in Nursing': 'Bachelor of Science in Nursing',
  'Bachelor of Science in Nutrition science':
    'Bachelor of Science in Nutrition science',
  'Bachelor of Science in Petroleum Engineering':
    'Bachelor of Science in Petroleum Engineering',
  'Bachelor of Science in Pharmacology': 'Bachelor of Science in Pharmacology',
  'Bachelor of Science in Pharmacy': 'Bachelor of Science in Pharmacy',
  'Bachelor of Science in Physical Therapy':
    'Bachelor of Science in Physical Therapy',
  'Bachelor of Science in Physics': 'Bachelor of Science in Physics',
  'Bachelor of Science in Physiology': 'Bachelor of Science in Physiology',
  'Bachelor of Science in Plant Science':
    'Bachelor of Science in Plant Science',
  'Bachelor of Science in Podiatry': 'Bachelor of Science in Podiatry',
  'Bachelor of Science in Politics': 'Bachelor of Science in Politics',
  'Bachelor of Science in Psychology': 'Bachelor of Science in Psychology',
  'Bachelor of Science in Public Safety':
    'Bachelor of Science in Public Safety',
  'Bachelor of Science in Quantity Surveying Engineering':
    'Bachelor of Science in Quantity Surveying Engineering',
  'Bachelor of Science in Radiologic Technology':
    'Bachelor of Science in Radiologic Technology',
  'Bachelor of Science in Real-Time Interactive Simulation':
    'Bachelor of Science in Real-Time Interactive Simulation',
  'Bachelor of Science in Religion': 'Bachelor of Science in Religion',
  'Bachelor of Science in Respiratory Therapy':
    'Bachelor of Science in Respiratory Therapy',
  'Bachelor of Science in Retail Management':
    'Bachelor of Science in Retail Management',
  'Bachelor of Science in Risk Management and Insurance':
    'Bachelor of Science in Risk Management and Insurance',
  'Bachelor of Science in Science Education':
    'Bachelor of Science in Science Education',
  'Bachelor of Science in Software Engineering':
    'Bachelor of Science in Software Engineering',
  'Bachelor of Science in Sports Management':
    'Bachelor of Science in Sports Management',
  'Bachelor of Science in Systems Engineering':
    'Bachelor of Science in Systems Engineering',
  'Bachelor of Science in Veterinary Technology':
    'Bachelor of Science in Veterinary Technology',
  'Bachelor of Secondary Education': 'Bachelor of Secondary Education',
  'Bachelor of Systems Engineering ': 'Bachelor of Systems Engineering ',
  'Bachelor of Technology ': 'Bachelor of Technology ',
  'Bachelor of Wireless Engineering ': 'Bachelor of Wireless Engineering ',
  'Bachelor of Science in Health Administration':
    'Bachelor of Science in Health Administration',
};

export const HS_DEGREES = {
  'High School Diploma': 'High School Diploma',
};

export const DEPARTMENTS = [
  'Accident and Emergency',
  'Admissions',
  'Adolescent and Young Adult',
  'Allergy and Immunology',
  'Ambulatory Surgery Center',
  'Anesthesiology',
  'Anesthetics',
  'Audiology',
  'Autism and Developmental Disabilities',
  'Biomedical Technology',
  'Brain Health',
  'Breast Screening',
  'Burn Center/Unit',
  'Cancer and Blood Disorders',
  'Cardiology',
  'Cardiothoracic Surgery',
  'Central Sterile Services Department',
  'Cerebrovascular Disorders',
  'Chaplaincy',
  'Child Life',
  'Community Clinics',
  'Coronary Care Unit',
  'Critical Care',
  'Dentistry',
  'Dermatology',
  'Diabetes',
  'Diagnostic Imaging',
  'Discharge Lounge',
  'Elderly Services',
  'Emergency Department',
  'Endocrinology',
  'Endoscopy',
  'Epilepsy',
  'Fetal Neonatology',
  'Finance Department',
  'Foster Care',
  'Gastroenterology',
  'General Medicine',
  'General Services',
  'General Surgery',
  'Genetics',
  'Geriatric Intensive Care Unit',
  'Geriatrics',
  'Gynecology',
  'Health and Safety',
  'Health Education',
  'Hematology',
  'HIV/AIDS Services',
  'Home Care',
  'Hospital Hygiene',
  'Human Resources',
  'Infection Control',
  'Infectious Disease',
  'Information Management',
  'Intensive Care Unit',
  'Internal Medicine',
  'Kidney Disease Clinic',
  'Laboratory Services',
  'Maternity',
  'Medical Records',
  'Microbiology',
  'Mobile Mammography',
  'Nanomedicine',
  'Neonatal',
  'Neonatal Intensive Care Unit',
  'Nephrology',
  'Neurology',
  'Neurosurgery',
  'Nursing',
  'Nutrition and Dietetics',
  'Obstetrics/Gynecology',
  'Occupational Health and Safety Department',
  'Occupational Therapy',
  'On-Call Room',
  'Oncology',
  'Operating Room',
  'Opthamology',
  'Oral and Maxillofacial Surgery',
  'Orthodontics',
  'Orthopedics',
  'Otolaryngology (Ear, Nose, and Throat)',
  'Pain Management',
  'Palliative Care',
  'Pathology and Genomic Medicine',
  'Patient Accounts',
  'Patient Services',
  'Pediatric Intensive Care Unit',
  'Pharmacy',
  'Physical Medicine and Rehabilitation',
  'Physical Therapy',
  'Physiotherapy',
  'Plastic and Craniofacial Surgery',
  'Post-Anesthesia Care Unit',
  'Primary Care',
  'Psychiatry',
  'Psychology',
  'Pulmonary Medicine',
  'Purchasing and Supplies',
  'Radiation Oncology',
  'Radiology',
  'Radiotherapy',
  'Rape Crisis Center',
  'Renal',
  'Respiratory Therapy',
  'Rheumatology',
  'Sexual Health',
  'Sleep Disorders',
  'Social Services',
  'Social Work',
  'Speech/Language Pathology',
  'Sports Medicine',
  'Stroke Center',
  'Surgical Services',
  'Systems Medicine and Bioengineering',
  'Transplant',
  'Trauma Center',
  'Urgent Care',
  'Urology',
];
export const CREDENTIAL_STATUS = {
  applicationSent: 'Application Sent',
  incomplete: 'Incomplete',
  inProgress: 'In Progress',
  pendingApproval: 'Pending Approval',
  processTermed: 'Process Termed',
  psvComplete: 'PSV Complete',
};

export const CREDENTIAL_ITEMS = {
  BackgroundCheck: 'Background Check',
  BoardCertification: 'Board Certification',
  CAQH: 'CAQH',
  CDS: 'CDS',
  COI: 'COI',
  DEA: 'DEA',
  DeathMaster: 'Death Master File',
  DrugScreening: 'Drug Screening',
  Education: 'Education',
  FSMBQuery: 'FSMB Query (MD/DO)',
  GovIssued: 'Gov. Issued Photo ID',
  HospitalAffiliate: 'Hospital Affiliations',
  LifeSavingCertifications: 'Life Saving Certifications',
  MedicareOptOutList: 'Medicare Opt-Out List',
  MilitaryService: 'Military Service',
  NPDB: 'NPDB',
  NPI: 'NPI',
  NSOW: 'NSOW',
  OIGQuery: 'OIG Query',
  OPIOIDTraining: 'Opioid Training',
  ProfessionalLicense: 'Professional License',
  References: 'References',
  SAM: 'SAM',
  Training: 'Training',
  Tricare: 'Tricare',
};

export const MD_DO_BOARD_LINK = {
  // 'N/A': '*Not applicable',
  '067': 'http://www.aacom.org/Pages/default.aspx',
  '068': 'http://www.aacom.org/Pages/default.aspx',
  '069': 'http://www.aaem.net/',
  '070': 'http://www.aans.org/',
  '071': 'http://www.abdominalsurg.org/board.html',
  '072': 'https://www.abam.net/',
  '073': 'http://www.abai.org/',
  '074': 'http://www.theaba.org/HOME',
  '075': 'http://www.boardofaudiology.org/',
  '076': 'http://www.lipidboard.org/',
  '077': 'https://theabcn.org/',
  '078': 'http://www.abcrs.org/',
  '079': 'https://www.abderm.org/',
  '080': 'http://www.abem.org/public/',
  '081': 'https://www.aae.org/board/',
  '082': 'https://www.abfprs.org/',
  '083': 'https://www.theabfm.org/',
  '084': 'https://www.abfas.org/',
  '085': 'http://www.abim.org/',
  '086': 'https://www.abipp.org/',
  '087': 'https://ables.org/',
  '088': 'http://www.abmgg.org/',
  // '089': "American Board of Medical Specialists in Podiatry ",
  '090': 'https://www.abmsp.org/',
  '091': 'http://www.abns.org/',
  '092': 'https://www.abnm.org/',
  '093': 'https://www.abog.org/',
  '094': 'https://abop.org/',
  '095': 'https://americanboardofoptometry.org/',
  '096': 'https://www.abomp.org/',
  '097': 'https://www.aaomr.org/',
  '098': 'https://www.aboms.org/',
  '099': 'https://www.abop.net/',
  '100': 'https://www.americanboardortho.com/',
  '101': 'https://www.abos.org/',
  '102': 'https://www.aboto.org/',
  '103': 'http://www.abpath.org/',
  '104': 'https://www.abp.org/',
  '105': 'https://abpns.org/',
  '106': 'https://www.abp.org/',
  '107': 'https://www.abperio.org/i4a/pages/index.cfm?pageid=1',
  '108': 'https://www.abpmr.org/',
  '109': 'http://www.abpts.org/home.aspx',
  '110': 'https://www.abpsus.org/',
  '111': 'https://www.abplasticsurgery.org/',
  '112': 'http://www.abprevmed.org/',
  '113': 'https://www.abpp.org/',
  '114': 'https://www.abpros.org/',
  '115': 'http://www.abpn.com/',
  '116': 'https://www.aaphd.org/abdph',
  '117': 'http://www.theabr.org/',
  '118': 'https://absm.org/',
  '119': 'http://www.absurgery.org/',
  '120': 'https://www.abts.org/',
  '121': 'http://www.abucm.org/',
  '122': 'http://www.abu.org/',
  '123': 'https://www.vascularboard.org/',
  '124': 'https://www.abvlm.org/',
  '125': 'https://abwh.net/',
  '126': 'http://www.abwmcertified.org/',
  '127': 'http://www.cancer.org/docroot/home/index.asp',
  '128': 'http://www.acc.org/',
  '129': 'http://www.chestnet.org/',
  '130': 'http://www.acep.org/',
  '131': 'http://www.acog.com/',
  '132': 'http://www.acponline.org/',
  '133': 'http://www.acpm.org/',
  '134': 'http://www.acr.org/',
  '135': 'http://www.rheumatology.org/',
  '136': 'http://www.facs.org/',
  // '137': "American Heart Association ",
  '138': 'http://www.aium.org/',
  // '139': "American Medical Association [AMA] ",
  '140': 'https://www.amcbmidwife.org/',
  '141': 'http://www.aneuroa.org/',
  '142': 'http://www.aoa-net.org/',
  '143': 'https://certification.osteopathic.org/anesthesiology/',
  '144': 'https://certification.osteopathic.org/dermatology/',
  '145': 'https://certification.osteopathic.org/emergency-medicine/',
  '146': 'https://certification.osteopathic.org/family-physicians/',
  '147': 'https://certification.osteopathic.org/internal-medicine/',
  '148': 'https://certification.osteopathic.org/neurology-psychiatry/',
  '149': 'https://certification.osteopathic.org/neuromusculoskeletal-medicine/',
  '150': 'http://www.aobnm.org/',
  '151': 'https://certification.osteopathic.org/obstetrics-gynecology/',
  '152': 'https://certification.osteopathic.org/ophthalmology-otolaryngology/',
  '153': 'https://certification.osteopathic.org/orthopedic-surgery/',
  '154': 'https://certification.osteopathic.org/pathology/',
  '155': 'https://certification.osteopathic.org/pediatrics/',
  '156':
    'https://certification.osteopathic.org/physical-medicine-rehabilitation/',
  '157': 'https://certification.osteopathic.org/preventive-medicine/',
  '158': 'https://certification.osteopathic.org/proctology/',
  '159': 'https://certification.osteopathic.org/radiology/',
  '160': 'https://certification.osteopathic.org/surgery/',
  '161': 'http://www.psych.org/',
  '162': 'http://www.ardms.org/Pages/default.aspx',
  '163': 'http://www.arrs.org/',
  '164': 'http://www.asbs.org/',
  '165': 'http://www.asds.net/',
  '166': 'http://www.asge.org/',
  '167': 'http://www.astro.org/',
  '168': 'http://www.asam.org/',
  '169': 'http://www.asahq.org/',
  '170': 'http://www.ascrs.org/',
  '171': 'http://www.asco.org/',
  '172': 'http://www.acponline.org/',
  '173': 'https://www.asnweb.org/i4a/pages/index.cfm?pageid=1',
  '174': 'http://www.asoprs.org/i4a/pages/index.cfm?pageid=1',
  '175': 'http://www.plasticsurgery.org/',
  '176': 'http://www.thoracic.org/',
  '177': 'http://www.thoracic.org/',
  '178': 'http://www.aoa-net.org/',
  // '179': "Association of Extremity Nerve Surgeons ",
  // '180': "Centers for Disease Control ",
  '181': 'http://www.cccvi.org/cbcct/',
  // '182': "Certification Board of Nuclear Cardiology ",
  '183': 'http://www.cap.org/',
  '184': 'http://www.cccvi.org/',
  '185': 'http://www.cmss.org/',
  '186': 'https://www.htcc.org/',
  '187': 'https://www.nawccb.org/',
  '188': 'https://www.echoboards.org/',
  '189': 'http://www.nbme.org/',
  '190': 'https://nbpas.org/',
  '191': 'http://newscenter.cancer.gov/',
  '192': 'https://www.nccpa.net/',
  '193': 'https://www.ncoa.org/',
  '194': 'http://www.nei.nih.gov/',
  '195': 'http://www.nhlbi.nih.gov/index.htm',
  '196':
    'http://www.hemophilia.org/NHFWeb/MainPgs/MainNHF.aspx?menuid=0&contentid=1',
  '197': 'http://www3.niaid.nih.gov/',
  '198': 'http://www.niams.nih.gov/',
  '199': 'http://www.niams.nih.gov/',
  '200': 'http://www.niddk.nih.gov/',
  '201': 'http://www.nia.nih.gov/',
  '202': 'http://www.nih.gov/',
  '203': 'http://www.royalcollege.ca/rcsite/home-e',
  '204': 'http://www.sidnet.org/',
  '205': 'https://vascular.org/',
  '206': 'http://www.sages.org/',
  '207': 'http://www.scvir.org/',
  '208': 'http://www.sccm.org/Pages/default.aspx',
  '209': 'http://www.sccm.org/Pages/default.aspx',
  '210': 'http://www.sts.org/',
  '211': 'https://www.ucns.org/',
  '212': 'https://ables.org/',
  '215': 'https://osteopathic.org/',
  '216': 'https://apca.org/Pages/default.aspx',
  '217': 'http://www.aacap.org/',
  '218': 'http://www.aacap.org/',
  '219': 'http://www.aad.org/',
  '220': 'http://www.aafp.org/ ',
  '221': 'https://aahivm.org/',
  '222': 'http://www.aan.com/',
  '223': 'http://www.aao.org/',
  '224': 'http://www.aaos.org/',
  '225': 'http://www.painmed.org/',
  '226': 'http://www.aap.org/',
  '227': 'http://www.aapmr.org/',
};

export const DDS_DMD_BOARD_LINK = {
  // 'N/A': '*Not applicable',
  '400': 'https://www.aaphd.org/',
  '401': 'https://www.aaoms.org/',
  '402': 'http://www.aapd.org/',
  '403': 'https://www.aaoms.org/',
  '404': 'https://www.aaoinfo.org/',
  '405': 'http://www.perio.org/',
  '406': 'http://www.perio.org/',
  '407': 'http://www.perio.org/',
};

export const DPM_BOARD_LINK = {
  '300': 'https://abpmed.org/',
  '301': 'https://www.aafas.org/',
  '302': 'https://aappm.org/',
  '303': 'http://www.aapsm.org/',
  '304': 'https://www.americanwomenpodiatrists.com/contact-us.htm',
  '305': 'https://www.aacpm.org/',
  '306': 'https://www.abpmed.org/',
  // '307': "American Board of Podiatric Orthopedics and Primary Podiatric Medicine",
  // '308': "American Board of Podiatric Surgery",
  '309': 'https://www.acfaom.org/',
  '310': 'https://www.acfas.org/',
  '311': 'https://acfap.org/',
  '312': 'https://www.apma.org/',
  '313': 'https://www.apmsa.org/',
  '314':
    'https://www.apha.org/apha-communities/member-sections/podiatric-health',
  '315': 'https://dermfoot.com/',
  '316': 'https://www.aspegroup.org/',
  '317': 'https://aspma.org/',
  // '318': "American Society of Podiatric Medicine",
  '319': 'https://www.aspsmembers.org/',
  '320': 'https://www.podiatrycanada.org/',
  '321': 'https://www.fpmb.org/',
  '322': 'https://www.apma.org/',
};

export const DC_BOARD_LINK = {
  '350': 'https://dcorthoacademy.org/',
  '351': 'http://www.acacoh.com/',
  '352': 'https://acbsp.com/',
  '353': 'https://www.acrb.org/',
  '354': 'https://www.cbcn.us/',
};

export const Allied_Health_BOARD_LINK = {
  // 'N/A': 'N/A',
  '1': 'https://www.aacn.org/',
  '2': 'https://aamft.org/',
  '3': 'https://www.aanpcert.org/',
  '4': 'https://www.audiology.org/american-board-of-audiology/',
  // "5": "ABESW-American Board of Examiners in Social Work",
  '6': 'https://www.nursingcertification.org/',
  '7': 'https://www.abpp.org/',
  '8': 'https://specialization.apta.org/',
  '9': 'https://www.socialworkers.org/',
  '10': 'https://www.amcbmidwife.org/',
  // "11": "AMECSW-American Board of Examiners in Clinical Social Work",
  '12': 'https://www.aacnnursing.org/',
  '13': 'http://www.aacap.org/',
  '14': 'http://www.aapmr.org/',
  '15': 'https://www.diabeteseducator.org/',
  '16': 'https://www.aamro.com/',
  '17': 'https://adta.org/',
  '18': 'http://www.aium.org/',
  '19': 'https://www.amcbmidwife.org/',
  '20': 'http://www.psych.org/',
  '21': 'https://www.apa.org/',
  '22': 'http://www.asam.org/',
  '23': 'https://www.counseling.org/',
  // "24": "ANCC (American Nurse Credentialing Center)",
  '25': 'https://www.atcb.org/',
  '26': 'https://www.asha.org/',
  '27': 'https://www.a4pt.org/',
  '28': 'https://www.aacn.org/',
  '29': 'https://www.bacb.com/',
  '30': 'https://www.bcia.org/i4a/pages/index.cfm?pageid=1',
  '31': 'http://www.bocatc.org/',
  '32': 'http://bonent.org/',
  '33': 'https://www.cbmt.org/',
  '34': 'https://www.cncb.org/',
  '35': 'https://ccmcertification.org/',
  '36': 'https://www.cdrnet.org/',
  '37': 'https://education.chaplinq.org/',
  '38': 'http://www.hancb.org/Index/index.php',
  '39': 'http://icapediatrics.com/',
  // "40": "International Alcohol & Drug Counselors",
  '41': 'https://iblce.org/',
  '42': 'https://www.iscd.org/',
  '43': 'https://kinesiotaping.com/',
  '44': 'https://www.socialworkers.org/',
  '45': 'https://naecb.com/',
  '46': 'https://nbcopa.org/',
  '47': 'https://www.nbcrna.com/',
  '48': 'http://www.nc-cm.org/index.htm',
  '49': 'https://www.diabeteseducator.org/education/certification',
  '50': 'https://www.nccaom.org/',
  '51': 'https://www.naadac.org/certification',
  '52': 'https://www.nccwebsite.org/',
  '53': 'https://www.ncpgambling.org/',
  '54': 'http://www.nidcd.nih.gov/',
  '55': 'https://www.nationalregister.org/',
  '56': 'https://www.nsca.com/',
  '57': 'https://www.nbcc.org/',
  '58': 'https://www.nbcot.org/',
  '59': 'https://nbeo.optometry.org/',
  '60': 'https://www.nccpa.net/verify-pa.aspx',
  '61': 'https://www.oncc.org/',
  '62': 'https://www.pncb.org/',
  '63': 'http://www.rsna.org/',
  '64': 'https://www.nccpa.net/',
  '65': 'https://tfcbt.org/',
  '66': 'https://www.wocncb.org/',
};

export const ALL_BOARDS_LINK = {
  ...MD_DO_BOARD_LINK,
  ...DDS_DMD_BOARD_LINK,
  ...DPM_BOARD_LINK,
  ...DC_BOARD_LINK,
  ...Allied_Health_BOARD_LINK,
};

export const ORGANIZATIONS_LOGO = {
  MATCH_ME: {
    host: 'cmmcred.com',
    logo: 'cmm_logo.png',
    whiteLogo: 'cmm_white_logo.png',
    newLogo: 'cmm_icon.png',
    email: 'dino@clinicalmatchme.com',
    favicon: 'assets/favicon_cmm.ico',
    title: 'CMM',
    mainColor: '#80d6ff',
    secondColor: '#1b4583',
  },
  EDDYNOW: {
    host: 'secure.eddynow.com',
    logo: 'logo.png',
    whiteLogo: 'logo.png',
    newLogo: 'eddy-new-log.png',
    email: '',
    favicon: 'favicon.ico',
    title: 'Eddy',
    mainColor: '#1ad272',
    secondColor: '#1ad272',
  },
};

export const referenceStatus = {
  ACCESS_REQUEST: 'request',
  ACCESS_APPROVED: 'approved',
  ACCESS_DENIED: 'denied',
  ACCESS_REVOKED: 'revoked',
  ACCESS_ARCHIVED: 'archived',
  ACCESS_LATER: 'later',
};

export const ORG_USER_ACCESS_LEVELS = {
  ADMIN: 'Administrator',
  DIRECTOR: 'Director',
  COORDINATOR: 'Manager',
  SPECIALIST: 'Specialist',
  CLERICAL: 'Read Only',
  ENROLLMENT_READONLY: 'Enrollment Read Only',
};

export const STATE_BOARD = {
  AL: 'https://abme.igovsolution.net/online/Lookups/Individual_Lookup.aspx',
  KY: 'http://web1.ky.gov/GenSearch/LicenseSearch.aspx?AGY=5',
  OH: 'https://elicense.ohio.gov/OH_HomePage',
  AK: 'https://www.commerce.alaska.gov/cbp/main/Search/Professional',
  LA: '',
  OK: 'https://www.okmedicalboard.org/search',
  AZ: 'https://azbomprod.azmd.gov/glsuiteweb/clients/azbom/public/webverificationsearch.aspx',
  ME: 'https://www.pfr.maine.gov/ALMSOnline/ALMSQuery/SearchIndividual.aspx?Board=376&AspxAutoDetectCookieSupport=1',
  OR: 'https://omb.oregon.gov/search',
  AR: 'https://www.armedicalboard.org/Default.aspx',
  MD: 'https://www.mbp.state.md.us/bpqapp/',
  PA: 'https://www.pals.pa.gov/#/page/search',
  AS: '',
  MA: 'https://findmydoctor.mass.gov/',
  PR: '',
  CA: 'https://search.dca.ca.gov/',
  MI: 'https://val.apps.lara.state.mi.us/License/Search',
  RI: 'https://www.pals.pa.gov/#/page/search',
  CO: 'https://apps.colorado.gov/dora/licensing/Lookup/LicenseLookup.aspx',
  MN: 'http://docfinder.docboard.org/mn/df/mndf.htm',
  SC: 'https://verify.llronline.com/LicLookup/Med/Med.aspx?div=16&AspxAutoDetectCookieSupport=1',
  CT: 'https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx',
  MS: 'https://gateway.msbml.ms.gov/verification/search.aspx',
  SD: 'https://login.sdbmoe.gov/public/services/verificationsearch',
  DE: 'https://delpros.delaware.gov/OH_VerifyLicense',
  MO: 'https://pr.mo.gov/licensee-search-division.asp',
  TN: 'https://apps.health.tn.gov/licensure/',
  DC: 'https://doh.force.com/ver/s/',
  MT: 'https://ebiz.mt.gov/POL/GeneralProperty/PropertyLookUp.aspx?isLicensee=Y&TabName=APO',
  TX: 'https://public.tmb.state.tx.us/HCP_Search/SearchNotice.aspx',
  FL: 'https://mqa-internet.doh.state.fl.us/MQASearchServices/HealthCareProviders',
  NE: 'https://ebiz.mt.gov/POL/GeneralProperty/PropertyLookUp.aspx?isLicensee=Y&TabName=APO',
  TT: '',
  GA: 'https://verify.sos.ga.gov/verification/',
  NV: 'https://online.nvbop.org/#/verifylicense',
  UT: 'https://secure.utah.gov/llv/search/index.html#',
  GU: '',
  NH: 'https://forms.nh.gov/licenseverification/',
  VT: 'https://mpb.health.vermont.gov/Lookup/LicenseLookup.aspx',
  HI: 'https://mypvl.dcca.hawaii.gov/public-license-search/',
  NJ: 'https://newjersey.mylicense.com/verification/Search.aspx?facility=N',
  VA: 'https://dhp.virginiainteractive.org/Lookup/Index',
  ID: 'https://elitepublic.bom.idaho.gov/IBOMPublic/LPRBrowser.aspx',
  NM: 'https://nmrldlpi.my.site.com/nmmb/s/searchlicense',
  VI: '',
  IL: 'https://www.illinois.gov/services/service.professional-license-look-up.html',
  NY: 'https://www.op.nysed.gov/verification-search',
  WA: 'https://fortress.wa.gov/doh/providercredentialsearch/',
  IN: 'https://mylicense.in.gov/everification/Search.aspx',
  NC: 'https://portal.ncmedboard.org/verification/search.aspx',
  WV: 'https://psychbd.wv.gov/license-info/license-search/Pages/default.aspx',
  IA: 'https://dia-hfd.iowa.gov/DIA_HFD/Home.do',
  ND: 'https://www.ndbom.org/public/find_verify/verify.asp',
  WI: 'https://licensesearch.wi.gov/',
  KS: 'https://www.kansas.gov/ksbn-verifications/search/records',
  MP: '',
  WY: 'https://wybomprod.glsuite.us/GLSuiteWeb/Clients/WYBOM/Public/Licenseesearch.aspx',
};

export const COMMON_FORMS_STEPS = [
  {
    summaryTitle: 'Personal Information',
    state: 'personalInfo',
    icon: 'fa-id-card',
    path: 'personal-info',
  },
  {
    summaryTitle: 'Educational Information',
    state: 'educational',
    icon: 'fa-graduation-cap',
    path: 'educational-info',
  },
  {
    summaryTitle: 'Training',
    state: 'training',
    icon: 'fa-graduation-cap',
    path: 'training',
  },
  {
    summaryTitle: 'Hospital Affiliations',
    state: 'affiliation',
    icon: 'fa-h-square',
    path: 'affiliations',
  },
  {
    summaryTitle: 'Specialties',
    state: 'specialties',
    icon: 'fa-certificate',
    path: 'specialties',
  },
  {
    summaryTitle: 'Primary Practice',
    state: 'practices',
    icon: 'fa-map-marker',
    path: 'primary-practice',
  },
  {
    summaryTitle: 'Work History',
    state: 'workHistory',
    icon: 'fa-briefcase',
    path: 'work-history',
  },
  {
    summaryTitle: 'Military Service',
    state: 'militaryService',
    icon: 'fa-briefcase',
    path: 'military-service',
  },
  {
    summaryTitle: null,
    state: 'references',
    icon: 'fa-users',
    path: 'professional-references',
  },
  {
    summaryTitle: 'Disclosure Questions',
    state: 'questions',
    icon: 'fa-list',
    path: 'disclosure-questions',
  },
  {
    summaryTitle: 'Provider Documents',
    state: 'documents',
    icon: 'fa-file',
    path: 'documents-overview',
  },
  {
    summaryTitle: null,
    state: 'summary',
    icon: 'fa-info',
    path: 'summary',
  },
];
