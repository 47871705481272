// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL: 'https://secureapi.eddynow.com/api/v1/',
  // API_URL2: 'https://secureapi.eddynow.com/api/v2/',
  // Base_URL: 'https://secureapi.eddynow.com',

  API_URL: 'https://devapi.eddynow.com/api/v1/',
  API_URL2: 'https://devapi.eddynow.com/api/v2/',
  Base_URL: 'https://devapi.eddynow.com',
  COGNITO_URL: 'https://eddydev.auth.us-east-1.amazoncognito.com',
  CLIENT_ID: '20jnguvojcsr0e6bdp8luvkans',
  REDIRECT_URI: 'https://dev.eddynow.com/auth/login',

  // API_URL: 'http://127.0.0.1:10000/api/v1/',
  // API_URL2: 'http://127.0.0.1:10000/api/v2/',
  // Base_URL: 'http://127.0.0.1:10000',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
